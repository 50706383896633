import { render, staticRenderFns } from "./requireDetail.vue?vue&type=template&id=74525dc7&scoped=true"
import script from "./requireDetail.vue?vue&type=script&lang=js"
export * from "./requireDetail.vue?vue&type=script&lang=js"
import style0 from "./requireDetail.vue?vue&type=style&index=0&id=74525dc7&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74525dc7",
  null
  
)

export default component.exports