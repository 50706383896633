// 商品管理
export default [
  {
    path: "/product",
    meta: { title: "商品管理" },
    component: () => import("../views/product/index.vue"),
    // redirect: '/product/all',
    children: [
      {
        path: "/product/all",
        meta: { title: "全部商品", isMenu: true },
        component: () => import("../views/product/all.vue"),
      },
      {
        path: "/product/nonstandardProduct",
        meta: { title: "非标商品", isMenu: true },
        component: () => import("../views/product/nonstandardProduct.vue"),
      },
      {
        path: "/product/new",
        meta: { title: "新品申请", isMenu: true },
        component: () => import("../views/product/new.vue"),
      },
      {
        path: "/product/stockRequest",
        meta: { title: "库存申请", isMenu: true },
        component: () => import("../views/product/stockRequest.vue"),
      },
      {
        path: "/product/category",
        meta: { title: "分类列表", isMenu: true },
        component: () => import("../views/product/category.vue"),
      },
      {
        path: "/product/shippingTemplate",
        meta: { title: "运费模板", isMenu: true },
        component: () => import("../views/product/shippingTemplate.vue"),
      },

      {
        path: "/product/feeSetting",
        meta: { title: "费用设置", isMenu: true },
        component: () => import("../views/product/feeSetting.vue"),
      },
      {
        path: '/product/validityWarning',
        meta: { title: '保质期预警通知', isMenu: true },
        component: () => import('../views/product/validityWarning.vue'),
      },
      {
        path: '/product/warningSetting',
        meta: { title: '保质期预警设置', isMenu: true },
        component: () => import('../views/product/warningSetting.vue'),
      },

      {
        path: "/product/detail/requireDetail",
        meta: { title: "库存申请详情" },
        component: () => import("../views/product/detail/requireDetail.vue"),
      },
      {
        path: "/product/detail/apply",
        meta: { title: "申请新商品" },
        component: () => import("../views/product/detail/apply.vue"),
      },
      {
        path: "/product/detail/nonstandardApply",
        meta: { title: "申请非标商品" },
        component: () => import("../views/product/detail/nonstandardApply.vue"),
      },
      {
        path: "/product/detail/modify",
        meta: { title: "修改" },
        component: () => import("../views/product/detail/modify.vue"),
      },
      {
        path: "/product/detail/info",
        meta: { title: "查看详情" },
        component: () => import("../views/product/detail/info.vue"),
      },
      {
        path: "/product/detail/nonstandardInfo",
        meta: { title: "查看非标商品详情" },
        component: () => import("../views/product/detail/nonstandardInfo.vue"),
      },
      {
        path: "/product/detail/addTemplate",
        meta: { title: "新增模板" },
        component: () => import("../views/product/detail/addTemplate.vue"),
      },
      {
        path: "/product/detail/updateTemplate",
        meta: { title: "修改模板" },
        component: () => import("../views/product/detail/updateTemplate.vue"),
      },
      {
        path: "/product/detail/relatedProducts",
        meta: { title: "关联商品" },
        component: () => import("../views/product/detail/relatedProducts.vue"),
      },
    ],
  },
]
