<!-- 销售明细 -->

<template>
  <div class="saleDetail">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-select v-model="orderTypeValue" placeholder="选择订单类型" @change="handleSelectOrderType" clearable>
          <el-option v-for="item in OrderOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-select v-model="paymentTypeValue" placeholder="选择支付方式" @change="handleSelectPaymentType" clearable>
          <el-option v-for="item in paymentOptions" :key="item.value" :label="item.label"
            :value="item.value"></el-option>
        </el-select>
        <el-select v-model="accountValue" placeholder="选择结算状态" @change="handleSelectAccount" clearable>
          <el-option v-for="item in accountOptions" :key="item.value" :label="item.label"
            :value="item.value"></el-option>
        </el-select>
        <el-select v-model="typeValue" placeholder="选择结算类型" @change="handleSelectType" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-date-picker v-model="dataValue" type="daterange" popper-class="sift-data" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSelectData" clearable
          value-format="yyyy-MM-dd"></el-date-picker>
        <el-input v-model="orderValue" placeholder="搜索订单号" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
      </div>
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="orderNum" label="订单号"
            width="150"></el-table-column>
          <el-table-column min-width="100" show-overflow-tooltip prop="orderSource" label="订单类型">
            <template slot-scope="scope">
              <!-- <span>{{ scope.row.orderSource == 1 ? '线下订单' : '线上订单' }}</span> -->
              <span v-if="scope.row.orderSource == 1">线下订单</span>
              <span v-if="scope.row.orderSource == 2">线上订单</span>
              <span v-if="scope.row.orderSource == 3">会员充值</span>
              <span v-if="scope.row.orderSource == 4">运费加价</span>
            </template>
          </el-table-column>
          <el-table-column min-width="100" show-overflow-tooltip prop="orderType" label="结算类型">
            <template slot-scope="scope">
              <!-- <span>{{ scope.row.orderType == 1 ? '销售' : '退货' }}</span> -->
              <span v-if="scope.row.orderType == 1">销售</span>
              <span v-if="scope.row.orderType == 2">退货</span>
              <span v-if="scope.row.orderType == 3">充值</span>
            </template>
          </el-table-column>
          <el-table-column min-width="100" prop="payType" label="支付方式" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>
                {{ getOrderTypeText(scope.row.payType) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="110" show-overflow-tooltip prop="payAmount" label="金额(元)"></el-table-column>
          <el-table-column min-width="110" show-overflow-tooltip prop="supplyAmount" label="进货金额(元)"
            width="140"></el-table-column>
          <el-table-column min-width="110" show-overflow-tooltip prop="deliveryAmount" label="骑手配送费(元)"
            width="140"></el-table-column>
          <!-- <el-table-column min-width="110" show-overflow-tooltip prop="shopAddFreight" label="运费加价(元)"
            width="140"></el-table-column> -->
          <el-table-column min-width="110" show-overflow-tooltip prop="settlementAmount" label="结算金额(元)"
            width="140"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="createdTime" label="时间"
            width="180"></el-table-column>
          <el-table-column min-width="90" show-overflow-tooltip prop="billStatus" label="是否结算">
            <template slot-scope="scope">
              <span v-if="scope.row.billStatus == 0">否</span>
              <span v-if="scope.row.billStatus == 1">是</span>
              <span v-if="scope.row.billStatus == -1">无需结算</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="200" fixed="right">
            <template slot-scope="scope">
              <el-button size="small" class="btn" type="primary" plain @click="handleCheck(scope.row)"
                v-if="scope.row.orderSource != 4">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      // 订单类型
      orderTypeValue: "",
      OrderOptions: [
        {
          value: "1",
          label: "线下订单",
        },
        {
          value: "2",
          label: "线上订单",
        },
        {
          value: "3",
          label: "会员卡充值",
        },
        {
          value: "4",
          label: "运费加价",
        },
      ],
      // 支付方式
      paymentTypeValue: "",
      paymentOptions: [
        {
          value: "0",
          label: "现金支付",
        },
        {
          value: "1",
          label: "线上支付",
        },
        {
          value: "3",
          label: "会员卡支付",
        },
      ],
      options: [
        {
          value: "1",
          label: "销售",
        },
        {
          value: "2",
          label: "退货",
        },
        {
          value: "3",
          label: "充值",
        },
      ],
      accountOptions: [
        {
          value: "-1",
          label: "无需结算",
        },
        {
          value: "1",
          label: "是",
        },
        {
          value: "0",
          label: "否",
        },
      ],
      accountValue: "", // 分账状态
      typeValue: "", // 选择类型
      dataValue: "", // 时间区间
      orderValue: "", // 搜索订单号
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("saleDetail"))
    this.orderTypeValue = obj?.orderTypeValue
    this.paymentTypeValue = obj?.paymentTypeValue
    this.accountValue = obj?.accountValue
    this.typeValue = obj?.typeValue
    this.dataValue = obj?.dataValue
    this.orderValue = obj?.orderValue
    this.currentPage = obj?.currentPage
    this.$saveOrUpdateCache("saleDetail", {})
    this.getSettlementInfoList()
  },
  methods: {
    // 获取列表
    getSettlementInfoList() {
      this.loading = true
      let params = {
        billStatus: this.accountValue || null, // 分账状态
        orderType: this.typeValue || null, // 选择结算类型
        orderNum: this.orderValue || null, // 搜索订单号
        payType: this.paymentTypeValue || null, // 支付方式
        orderSource: this.orderTypeValue || null, // 订单类型
        shopId: this.$store.state.userInfo.shopId, //shopId
        settlementType: 1,
        page: this.currentPage,
        pageSize: this.pageSize,
      }

      // 时间区间
      if (this.dataValue && this.dataValue.length === 2) {
        params.startTime = this.dataValue[0]
        params.endTime = this.dataValue[1]
      } else {
        params.startTime = null
        params.endTime = null
      }

      this.$axios
        .get(this.$api.getSettlementInfoList, { params })
        .then(res => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list
            this.totalItemsCount = res.data.result.totalCount
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 查看
    handleCheck(row) {
      let obj = {
        orderTypeValue: this.orderTypeValue,
        paymentTypeValue: this.paymentTypeValue,
        accountValue: this.accountValue,
        typeValue: this.typeValue,
        dataValue: this.dataValue,
        orderValue: this.orderValue,
        currentPage: this.currentPage,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("saleDetail", obj)

      if (row.orderSource == 1 || row.orderSource == 2) {
        this.$router.push({ path: "/settlement/detail/settlementDetail", query: { id: row.id } })
      } else if (row.orderSource == 3) {
        this.$router.push({ path: "/settlement/detail/membershipCardPayDetail", query: { orderCode: row.orderNum } })
      }
    },
    // 清空
    clearData() {
      this.tableData = []
      this.currentPage = 1
    },
    // 搜索
    onSearch() {
      this.clearData()
      this.getSettlementInfoList()
    },
    // 选择订单类型
    handleSelectOrderType(value) {
      this.orderTypeValue = value
      this.onSearch()
    },
    // 选择支付类型
    handleSelectPaymentType(value) {
      this.paymentTypeValue = value
      this.onSearch()
    },
    // 分账状态
    handleSelectAccount(value) {
      this.accountValue = value
      this.onSearch()
    },
    // 选择类型
    handleSelectType(value) {
      this.typeValue = value
      this.onSearch()
    },
    // 时间搜索
    handleSelectData(value) {
      this.dataValue = value
      this.onSearch()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e
      this.onSearch()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getSettlementInfoList()
    },
    // 支付类型
    getOrderTypeText(status) {
      switch (String(status)) {
        case "0":
          return "现金支付"
        case "1":
          return "线上支付"
        case "3":
          return "会员卡支付"
        default:
          return ""
      }
    },
  },
}
</script>

<style scoped lang="scss">
.saleDetail {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    .el-input {
      width: 200px;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    .el-select {
      margin-left: 20px;
      margin-bottom: 20px;
    }

    /deep/ .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    /deep/.el-range-input {
      background-color: #f2f2f2;
    }
  }
}
</style>
