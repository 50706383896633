<!-- 预警设置 -->

<template>
  <div class="setting">
    <div class="globle_border" v-loading="loading">
      <div class="search" style=" display: flex;justify-content: flex-start; align-items: center;">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane :label="item.label" :name="item.name" v-for="(item, index) in tabs" :key="index"></el-tab-pane>
        </el-tabs>
        <el-button style="margin-left: 10px;" type="primary" @click="batchChangeStatus">批量设置</el-button>
      </div>

      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="productName" placeholder="请搜索商品名称" suffix-icon="el-icon-search" @change="onSearch" clearable
          v-if="activeName === '1'"></el-input>
        <el-input v-model="barCode" placeholder="请搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch" clearable
          v-if="activeName === '1'"></el-input>
        <el-input v-model="brandName" placeholder="请搜索品牌名称" suffix-icon="el-icon-search" @change="onSearch" clearable
          v-if="activeName === '2'"></el-input>
        <el-input v-model="firstCategoryName" placeholder="请搜索一级类目名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable v-if="activeName === '3'"></el-input>
        <el-input v-model="secondCategoryName" placeholder="请搜索二级类目名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable v-if="activeName === '3'"></el-input>

      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="95" fixed="left" class="selection-column">
          </el-table-column>
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <template v-for="(item, index) in newTableLabel">
            <el-table-column :prop="item.prop" :label="item.label" show-overflow-tooltip :key="index"
              v-if="item.label != '操作'"></el-table-column>
            <el-table-column label="操作" header-align="center" align="center" width="200" :key="`else-${index}`"
              v-if="item.label == '操作'">
              <template slot-scope="scope">
                <el-button size="small" class="btn" type="primary" plain @click="handleSet(scope.row)">设置</el-button>
              </template>
            </el-table-column>
          </template>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>


    <!-- 弹窗 -->
    <el-dialog title="设置" :visible.sync="dialogVisible" @close="handleCloseDialog">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="最小库存预警" prop="minInventoryWarning">
              <el-input v-model="ruleForm.minInventoryWarning" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预警周期(天)" prop="warningCycle">
              <el-input v-model="ruleForm.warningCycle" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog" type="primary" plain>取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      dayPass: false,
      activeName: "1",//
      productId: null,
      productName: '', // 搜索商品名称
      barCode: '', // 搜索商品条形码
      brandName: '', // 搜索商品品牌
      firstCategoryName: '', // 一级类目
      secondCategoryName: '', // 二级类目
      setType: '', //设置类型
      tabs: [
        { label: "按商品设置", name: "1" },
        { label: "按品牌设置", name: "2" },
        { label: "按品类设置", name: "3" },
      ],
      tableLabel: [
        // 按商品、品牌设置
        { prop: "barCode", label: "商品条形码", activeName: ["1"] },
        { prop: "productName", label: "商品名称", activeName: ["1"] },
        { prop: "specs", label: "商品规格", activeName: ["1"] },
        { prop: "categoryName", label: "商品类目", activeName: ["1"] },
        { prop: "enterBox", label: "入箱数", activeName: ["1"] },
        { prop: "brand", label: "商品品牌", activeName: ["1"] },
        { prop: "validityPeriod", label: "保质期(天)", activeName: ["1"] },
        { prop: "inventoryCount", label: "库存", activeName: ["1"] },
        { prop: "brandName", label: "商品品牌", activeName: ["2"] },
        { prop: "firstCategoryName", label: "一级类目", activeName: ["3"] },
        { prop: "secondCategoryName", label: "二级类目", activeName: ["3"] },
        { prop: "warnCount", label: "最小库存预警", activeName: ["1", "2", "3"] },
        { prop: "warnPeriod", label: "预警周期(天)", activeName: ["1", "2", "3"] },
        { prop: "", label: "操作", activeName: ["1", "2", "3"] },
      ],
      newTableLabel: [],
      // 表格数据
      tableData: [],
      multipleSelection: [], // 选中数据
      // 弹窗数据
      dialogVisible: false, // 弹窗显隐
      ruleForm: {
        minInventoryWarning: '',
        warningCycle: '',
      },
      rules: {
        minInventoryWarning: [{ required: true, message: '请输入', trigger: 'blur' }, { pattern: /^[1-9]\d*$/, message: '不能输入数字以外的字符', trigger: 'blur' }],
        warningCycle: [{ required: true, message: '请输入', trigger: 'blur' }, { pattern: /^[1-9]\d*$/, message: '不能输入数字以外的字符', trigger: 'blur' }],
      },
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.handleClick()
  },

  methods: {
    // 请求商品列表
    selectInventoryWarnConfigByShopProduct() {
      this.loading = true;
      this.$axios.post(this.$api.selectInventoryWarnConfigByShopProduct, {
        bizUserType: 1,//类型：1商超；2供应商
        bizUserId: this.$store.state.userInfo.shopId, //shopId
        barCode: this.barCode || null,  // 搜索商品编号
        productName: this.productName || null,  // 搜索商品名称
        page: this.currentPage,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.data.code == 100) {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    // 请求品牌列表
    selectInventoryWarnConfigByBrand() {
      this.loading = true;
      this.$axios.post(this.$api.selectInventoryWarnConfigByBrand, {
        bizUserType: 1,//类型：1商超；2供应商
        bizUserId: this.$store.state.userInfo.shopId, //shopId
        brandName: this.brandName || null,  // 搜索商品品牌
        page: this.currentPage,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.data.code == 100) {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    // 请求品类列表
    selectInventoryWarnConfigByCategory() {
      this.loading = true;
      this.$axios.post(this.$api.selectInventoryWarnConfigByCategory, {
        bizUserType: 1,//类型：1商超；2供应商
        bizUserId: this.$store.state.userInfo.shopId, //shopId
        firstCategoryName: this.firstCategoryName || null,  // 一级分类名称
        secondCategoryName: this.secondCategoryName || null,  // 二级分类名称
        page: this.currentPage,
        pageSize: this.pageSize,
      }).then((res) => {
        if (res.data.code == 100) {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    // 数据列表请求
    request() {
      if (this.activeName == "1") this.selectInventoryWarnConfigByShopProduct()
      if (this.activeName == "2") this.selectInventoryWarnConfigByBrand()
      if (this.activeName == "3") this.selectInventoryWarnConfigByCategory()
    },
    // 切换
    handleClick() {
      // tableLabel数组过滤activeName等于this.activeName的元素
      this.newTableLabel = this.tableLabel.filter(item => item.activeName.includes(this.activeName))
      this.request()
    },
    // 设置
    handleSet(row) {
      this.dialogVisible = true;
      this.setType = '';
      if (this.activeName == "1") this.productId = row.productSupplierId
      if (this.activeName == "2") this.productId = row.brandId
      if (this.activeName == "3") this.productId = row.secondCategoryId
      this.$set(this.ruleForm, 'warningCycle', row.warnPeriod)
      this.$set(this.ruleForm, 'minInventoryWarning', row.warnCount)
    },
    // 全选选中
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 批量设置
    batchChangeStatus() {
      if (this.multipleSelection.length == 0) {
        this.$message.warning("请先选择商品！")
        return
      }
      this.dialogVisible = true;
      this.setType = 'all';
    },
    // 关闭弹窗
    handleCloseDialog() {
      this.dialogVisible = false;
      this.resetForm();
      // this.multipleSelection = []
      // this.productId = null
      // this.request()
    },
    // 表单校验
    submitForm(formName) {
      let ids;
      if (this.activeName == "1") ids = this.multipleSelection.map(item => item.productSupplierId)
      if (this.activeName == "2") ids = this.multipleSelection.map(item => item.brandId)
      if (this.activeName == "3") ids = this.multipleSelection.map(item => item.secondCategoryId)
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          console.log('error submit!!');
          return false;
        } else {
          this.$axios.post(this.$api.insertInventoryWarnConfig, {
            bizIdList: this.setType == 'all' ? ids : [this.productId],
            bizType: this.activeName,
            bizUserType: 1,//类型：1商超；2供应商
            bizUserId: this.$store.state.userInfo.shopId, //shopId
            warnCount: this.ruleForm.minInventoryWarning, // 最小预警数量
            warnPeriod: this.ruleForm.warningCycle, // 预警周期
          }).then((res) => {
            this.$message.success(res.data.desc)
            this.request()

          }).catch((err) => {
            this.$message.error(err.data.desc)
            this.request()
          })
        }
        this.handleCloseDialog();
      });
    },
    // 重置表单
    resetForm() {
      this.ruleForm = {};
      this.$refs.ruleForm.resetFields();
    },

    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.request()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.request()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.request()
    },

  }

}



</script>

<style scoped lang="scss">
.setting {


  ::v-deep .el-tabs--card>.el-tabs__header {
    border-bottom: none !important;
    margin: 0 !important;
  }

  ::v-deep .el-tabs__item {
    color: #707070 !important;
  }

  ::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item {}

  ::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: 1px solid #e2e2e2;
  }

  ::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    background: #069bbc;
    color: #ffffff !important;
    border-bottom: none !important;
  }

  ::v-deep .el-tabs__item:hover {
    color: #707070 !important;
  }

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 180px;
      margin-left: 10px;
    }
  }

  h2 {
    padding-left: 150px;
    margin: 20px 0;
  }

  /*表格表头全选*/
  /*要添加deep，不然没效果*/
  /deep/ .el-table__header .el-table-column--selection .cell .el-checkbox:after {
    color: rgba(3, 16, 14, 0.9);
    content: "全选当页";
    font-size: 14px;
    margin-left: 5px;
    font-weight: bold;
  }

  .row-bottom {

    /deep/.el-input,
    /deep/.el-input__inner {
      width: 255px;
    }

    /deep/ .el-form-item__label {
      color: #272727;
      font-size: 16px;
      padding-right: 30px;
    }

    /deep/.el-input__inner {
      background: #f2f2f2;
      border-radius: 10px 0 0 10px;
      border: none;
    }

    /deep/ .el-input-group__append {
      background: #f2f2f2;
      border-radius: 0px 10px 10px 0;
      border: none;
      color: #000;
    }

    .left {
      color: #272727;
      font-size: 16px;
      margin-left: 20px;
    }
  }
}

/* input number类型取消增减箭头*/
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

// 光标上移
/deep/.el-input__inner {
  line-height: 1px !important;
}

.day {

  /deep/.el-input,
  /deep/.el-input__inner {
    width: 200px;
  }

  /deep/ .el-form-item__label {
    color: #272727;
    font-size: 16px;
    padding-right: 30px;
  }

  /deep/.el-input__inner {
    background: #f2f2f2;
    border-radius: 10px 0 0 10px;
    border: none;
  }

  /deep/ .el-input-group__append {
    background: #f2f2f2;
    border-radius: 0px 10px 10px 0;
    border: none;
    color: #000;
  }
}
</style>
