<!-- 非标商品 -->

<template>
  <div class="nonstandardProduct">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="PLUCode" placeholder="搜索PLU编号" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="productName" placeholder="搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="barCode" placeholder="搜索商品编码" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-button class="btn" type="primary" @click="handleAdd">新增</el-button>
        <el-button class="btn" type="primary" @click="handleExport">导出</el-button>
      </div>
      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="pluNum" label="PLU编号"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="productCode" label="商品编号"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="validityDay" label="保质期(天)"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="salePrice" label="单价(元/kg)"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="specsUnit" label="规格"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="modeType" label="模式">
            <template slot-scope="scope">
              <span v-if="scope.row.modeType == 0">称重</span>
              <span v-if="scope.row.modeType == 1">计件</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="180" fixed="right">
            <template slot-scope="scope">
              <el-button size="small" class="btn" type="primary" plain @click="handleCheck(scope.row)">查看</el-button>
              <el-button size="small" class="btn" type="danger" plain @click="handleDelete(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      PLUCode: "", // PLU编号
      productName: "", // 商品名称
      barCode: "", // 商品编码
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getShopLabelProductList();
  },
  methods: {
    // 获取商品列表
    getShopLabelProductList() {
      this.loading = true;
      this.$axios.get(this.$api.getShopLabelProductList, {
        params: {
          pluNum: this.PLUCode || null,
          productName: this.productName || null,
          productCode: this.barCode || null,
          page: this.currentPage,
          pageSize: this.pageSize,
        }
      }).then((res) => {
        if (res.data.code == 100) {
          this.tableData = res.data.result.list;
          this.totalItemsCount = res.data.result.totalCount;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    handleCheck(row) {
      this.$router.push({
        path: '/product/detail/nonstandardInfo',
        query: {
          productId: row.productId
        }
      })
    },
    handleAdd() {
      this.$router.push('/product/detail/nonstandardApply')
    },
    // 删除
    handleDelete(row) {
      this.$confirm('确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        this.$axios.post(`${this.$api.deleteShopLabelProduct}/${row.productId}`)
          .then((res) => {
            if (res.data.code == 100) {
              this.$message.success(res.data.desc)
            }
            this.getShopLabelProductList()
          })
      }).catch((err) => {
        this.$message.err(err.data.desc)
      });
    },
    handleExport() {
      this.$axios
        .post(this.$api.exportExcelShopLabelProduct, {
          pluNum: this.PLUCode || null,
          productName: this.productName || null,
          productCode: this.barCode || null,
          page: this.currentPage,
          pageSize: this.pageSize,
        }, {
          responseType: 'blob' // 设置响应类型为blob
        })
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/octet-stream'
          });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = '非标商品.xlsx';
          link.style.display = 'none';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(link.href);
        })
        .catch(() => {
          this.$message.error("下载出错")
        });
    },

    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getShopLabelProductList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e;
      this.onSearch();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getShopLabelProductList();
    },

  }

}
</script>

<style lang="scss" scoped>
.nonstandardProduct {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 250px;
    }

    .el-input,
    .el-select,
    .el-button {
      margin-left: 20px;
    }

  }
}
</style>
