<!-- 提现申请 -->

<template>
  <div class="withdrawalApplication">
    <div class="globle_border" v-loading="loading">
      <!-- 账户总览 -->
      <div class="account">
        <div class="title">账户总览</div>
        <div class="tab">
          <div class="item">
            <div class="tab-top"><span>￥</span><span class="price">{{ formattedAmount(settleAccount.availableAmount)
                }}</span></div>
            <div class="tab-bottom">可提现金额</div>
          </div>
          <div class="item">
            <div class="tab-top"><span>￥</span><span class="price">{{ formattedAmount(settleAccount.settlementAmount)
                }}</span></div>
            <div class="tab-bottom">待结算金额</div>
          </div>
          <div class="item">
            <div class="tab-top"><span>￥</span><span class="price">{{ formattedAmount(settleAccount.toadyAmount)
                }}</span></div>
            <div class="tab-bottom">今天营业额</div>
          </div>
          <div class="item">
            <div class="tab-top"><span>￥</span><span class="price">{{ formattedAmount(settleAccount.totalAmount)
                }}</span></div>
            <div class="tab-bottom">总金额</div>
          </div>
        </div>
      </div>

      <!-- 顶部搜索 -->
      <div class="search">
        <div class="left">
          <el-button class="btn" type="primary" @click="handleApply">提现</el-button>
          <el-button class="btn" type="primary" @click="handleToAccountPage">收款账号管理</el-button>
        </div>
        <div class="right">
          <el-select v-model="typeValue" placeholder="选择状态" @change="handleSelectType" clearable>
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input v-model="orderValue" placeholder="搜索提现单号" suffix-icon="el-icon-search" @change="onSearch"
            clearable></el-input>
        </div>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" :cell-style="reviewRowClass">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="applyNum" label="提现单号"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="createdUserName" label="操作人"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="createdTime" label="提现时间"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="applyAmount" label="提现金额(元)"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="accountNum" label="收款账户"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="accountType" label="账户类型">
            <template slot-scope="scope">
              <span>{{ scope.row.accountType == '1' ? '支付宝' : '银行卡' }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="accountName" label="真实姓名"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="status" label="状态">
            <template slot-scope="scope">
              <span>{{ getStatusText(scope.row.status) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="200">
            <template slot-scope="scope">
              <el-button size="small" class="btn" type="primary" plain @click="handleCheck(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

    <!-- 提现 弹窗 -->
    <el-dialog title="提现" :visible.sync="dialogVisible" @close="handleCloseDialog">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-row class="row">
          <el-col :span="12" class="col">
            <el-form-item label="可提现金额：" prop="withdrawableAmount">
              <el-input v-model="ruleForm.withdrawableAmount" readonly></el-input>
            </el-form-item>
            <el-form-item label="收款账号：" prop="accountNumber">
              <el-select v-model="ruleForm.accountNumber" placeholder="请选择" clearable @change="handleSelectVto">
                <el-option v-for="item in accountNumberOptions" :key="item.value" :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="提现金额：" prop="withdrawalAmount">
              <el-input v-model="ruleForm.withdrawalAmount" placeholder="请输入"></el-input>
              <span class="all" @click="handleAll">全部提现</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      options: [{
        value: '0',
        label: '待审核'
      }, {
        value: '1',
        label: '已转账'
      }, {
        value: '2',
        label: '审核失败'
      }, {
        value: '3',
        label: '转账失败'
      },],
      typeValue: '', // 选择类型
      orderValue: '', // 搜索订单号
      // 表格数据
      tableData: [],
      // 账户总览数据
      settleAccount: {},
      // 提现弹窗数据
      dialogVisible: false, // 弹窗显隐
      ruleForm: {
        withdrawableAmount: '', // 可提现金额
        withdrawalAmount: '', // 提现金额
        accountNumber: '', // 收款账户
      },
      rules: {
        withdrawalAmount: [{
          required: true,
          message: '请输入提现金额',
          trigger: 'blur'
        },
        {
          validator: (rule, value, callback) => {
            if (value > this.ruleForm.withdrawableAmount) {
              callback(new Error('提现金额不能大于可提现金额'));
            } else if (/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(value) == false) {
              callback(new Error("提现金额请输入两位小数"));
            } else if (value < 0.1) {
              callback(new Error('提现金额必须大于等于0.1'));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }
        ],
        accountNumber: [{
          required: true,
          message: '请选择收款账户',
          trigger: 'blur'
        }],
      },
      accountNumberOptions: [],
      accountVo: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("withdrawalApplication"))
    this.typeValue = obj?.typeValue
    this.orderValue = obj?.orderValue
    this.currentPage = obj?.currentPage
    this.$saveOrUpdateCache("withdrawalApplication", {})
    this.getShopExtractApplyList();
    this.getSelectSettlementAccount();
  },
  methods: {
    // 获取列表
    getShopExtractApplyList() {
      this.loading = true;
      let params = {
        status: this.typeValue || null, // 选择类型
        applyNum: this.orderValue || null, // 搜索提现单号
        shopId: this.$store.state.userInfo.shopId, //shopId
        page: this.currentPage,
        pageSize: this.pageSize,
      };

      this.$axios.get(this.$api.getShopExtractApplyList, {
        params
      })
        .then((res) => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list;
            this.totalItemsCount = res.data.result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取账户总览
    getSelectSettlementAccount() {
      let params = {
        userType: '2', // 用户类型
        userId: this.$store.state.userInfo.shopId,
      };

      this.$axios.get(this.$api.getSelectSettlementAccount, {
        params
      })
        .then((res) => {
          if (res.data.code == 100) {
            this.settleAccount = res.data.result;
          }
        })
    },
    // 提现-下拉框选择账户
    getSelectAccountNumberList() {
      let params = {
        acountType: '1',
        page: 1,
        pageSize: 9999,
      };

      this.$axios.get(this.$api.getExtractAccountDrop, {
        params
      })
        .then((res) => {
          if (res.data.code == 100) {
            this.accountVo = res.data.result;
            const accountNumberOptions = res.data.result.map(item => ({
              label: item.accountNum,
              value: item.accountNum
            }));
            this.accountNumberOptions = accountNumberOptions;
          }
        })
    },
    handleSelectVto(value) {
      for (let i = 0; i < this.accountVo.length; i++) {
        if (this.accountVo[i].accountNum === value) {
          this.accountVo = this.accountVo[i]
          // console.log("找到匹配的对象：", this.accountVo);
          break;
        }
      }
    },
    // 打开提现弹窗
    handleApply() {
      this.dialogVisible = true;
      this.getSelectAccountNumberList();
      this.ruleForm.withdrawableAmount = this.settleAccount.availableAmount;
    },
    // 提现全部
    handleAll() {
      this.$set(this.ruleForm, 'withdrawalAmount', this.ruleForm.withdrawableAmount)
    },
    // 关闭弹窗
    handleCloseDialog() {
      this.dialogVisible = false;
      this.resetForm();
    },
    // 表单校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          console.log('error submit!!');
          return false;
        } else {
          this.$axios.post(this.$api.shopExtractApply, {
            applyAmount: this.ruleForm.withdrawalAmount, // 提现金额
            accountName: this.accountVo.accountName,
            accountNum: this.accountVo.accountNum,
            accountType: this.accountVo.accountType,
            opeBank: this.accountVo.opeBank,
            userId: this.accountVo.userId,
            userName: this.accountVo.userName,
            bankType: this.accountVo.bankType,
            bankCode: this.accountVo.bankCode,

          }).then((res) => {
            if (res.data.code == 100) {
              this.$message.success('申请成功!');
              this.handleCloseDialog();
              this.getShopExtractApplyList();
              this.getSelectSettlementAccount();
            }
          })
        }
        this.handleCloseDialog();
      });
    },
    // 重置表单
    resetForm() {
      this.ruleForm = {};
      this.$refs.ruleForm.resetFields();
    },

    // 收款账号管理详情
    handleToAccountPage() {
      let obj = {
        currentPage: this.currentPage,
        typeValue: this.typeValue,
        orderValue: this.orderValue,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("withdrawalApplication", obj)
      this.$router.push('/settlement/detail/accountManagement')
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getShopExtractApplyList();
    },
    // 查看
    handleCheck(row) {
      let obj = {
        currentPage: this.currentPage,
        typeValue: this.typeValue,
        orderValue: this.orderValue,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("withdrawalApplication", obj)

      this.$router.push({ path: '/settlement/detail/applyDetail', query: { 'id': row.id } })
    },
    // 选择类型
    handleSelectType(value) {
      this.clearData();
      this.typeValue = value;
      this.getShopExtractApplyList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getShopExtractApplyList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getShopExtractApplyList();
    },
    getStatusText(status) {
      switch (status) {
        case '0':
          return "待审核";
        case '1':
          return "已转账";
        case '2':
          return "审核失败";
        case '3':
          return "转账失败";
        default:
          return " ";
      }
    },
    formattedAmount(amount) {
      return amount ? amount.toFixed(2) : '0.00';
    },
    // 审核颜色变化
    reviewRowClass({ row, columnIndex }) {
      if (columnIndex == 8) {
        if (row.status == '0' || row.status == '2' || row.status == '3') {
          return { color: 'red' }
        }
      }
    },
  }

}
</script>

<style lang="scss" scoped>
.withdrawalApplication {

  // 账户总览
  .account {
    padding: 30px 0;

    .title {
      height: 21px;
      font-weight: bold;
      font-size: 16px;
      color: #1A1A1A;
      font-family: Microsoft YaHei, Microsoft YaHei;

    }

    .tab {
      display: flex;
      justify-content: space-around;
      align-items: center;
      text-align: center;

      .tab-top {
        color: rgba(235, 37, 0, 1);

        .price {
          font-size: 26px;
        }
      }

      .tab-bottom {
        margin-left: 20px
      }
    }
  }

  // 顶部搜索
  .search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;
    margin-top: 30px;

    .el-input {
      width: 300px;
      margin-left: 20px;
    }

    .el-select {
      margin-left: 20px;
    }

    /deep/ .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
    }

    /deep/.el-range-input {
      background-color: #f2f2f2;

    }
  }

  .all {
    color: #069BBC;
    margin-left: 15px;
    cursor: pointer;
  }
}
</style>
