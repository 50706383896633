<!-- 非标商品-新增/修改 -->
<template>
  <div class="apply">
    <back />

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
      <el-row class="dialog_row">
        <el-form-item class="item" label="PLU编号" prop="PLUCode">
          <el-input v-model="ruleForm.PLUCode" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item class="item" label="商品名称" prop="productName">
          <el-input v-model="ruleForm.productName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item class="item" label="商品编码" prop="barCode">
          <el-input v-model="ruleForm.barCode" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item class="item" label="保质期(天)" prop="qualityDate">
          <el-input v-model="ruleForm.qualityDate" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item class="item" label="模式" prop="mode">
          <el-select v-model="ruleForm.mode" placeholder="请选择" clearable @change="handleSelect">
            <el-option v-for="(item, index) in modeOptions" :key="index" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="item" label="规格(件)" prop="specs" v-if="ruleForm.mode == 1">
          <el-input v-model="ruleForm.specs" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item class="item" label="单价(元/kg)" prop="price">
          <el-input v-model="ruleForm.price" placeholder="请输入"></el-input>
        </el-form-item>
      </el-row>

      <div class="submit-btn">
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </div>
    </el-form>

  </div>
</template>

<script>
export default {

  data() {
    return {
      modeOptions: [{
        label: '称重',
        value: '0'
      },
      {
        label: '计件',
        value: '1'
      },
      ], // 模式
      ruleForm: {
        PLUCode: '',
        qualityDate: '',
        productName: '',
        mode: '',
        barCode: '',
        price: '',
        specs: '',
      },
      rules: {
        PLUCode: [
          {
            required: true,
            message: '请输入PLU编号',
            trigger: 'blur'
          },
          {
            pattern: /^(?:[1-9][0-9]{0,2}|4000)$/,
            message: 'PLU编号必须是1到4000之间的数字',
            trigger: 'blur'
          }
        ],
        qualityDate: [{
          required: true,
          message: '请输入保质期',
          trigger: 'blur'
        }, {
          pattern: /^\d+(\.\d{1,2})?$/,
          message: '只能输入数字',
          trigger: 'blur'
        }],
        productName: [{
          required: true,
          message: '请输入商品名称',
          trigger: 'blur'
        }],
        mode: [{
          required: true,
          message: '请选择模式',
          trigger: 'blur'
        }],
        barCode: [{
          required: true,
          message: '请输入商品编码',
          trigger: 'blur'
        }, {
          pattern: /^\d{6}$/,
          message: '只能输入6位数字',
          trigger: 'blur'
        }],
        price: [{
          required: true,
          message: '请输入单价',
          trigger: 'blur'
        }, {
          pattern: /^\d+(\.\d{1,2})?$/,
          message: '只能输入数字，最多保留两位小数',
          trigger: 'blur'
        }],
        specs: [{
          required: true,
          message: '请输入规格',
          trigger: 'blur'
        }, {
          pattern: /^\d+(\.\d{1,2})?$/,
          message: '只能输入数字',
          trigger: 'blur'
        }],
      },
    }
  },
  created() {

  },
  methods: {
    handleSelect(e) {
      if (e === '1') {
        this.ruleForm.specs = ''; // 清空规格字段
      }
    },
    // 表单校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post(this.$api.saveShopLabelProduct, {
            modeType: this.ruleForm.mode,
            pluNum: this.ruleForm.PLUCode,
            productCode: this.ruleForm.barCode,
            productName: this.ruleForm.productName,
            salePrice: this.ruleForm.price,
            specsUnit: this.ruleForm.specs,
            validityDay: this.ruleForm.qualityDate,
            shopId: this.$store.state.userInfo.shopId, // 商超id

          }).then((res) => {
            if (res.data.code == 100) {
              this.$message.success(res.data.desc);
              this.$router.go(-1);
              this.resetForm();
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

  },
}
</script>

<style lang="scss" scoped>
.dialog_row {
  display: flex;
  flex-wrap: wrap;

  /deep/.el-form-item__label {
    color: #272727;
    font-size: 16px;
    padding-right: 30px;
  }

  .el-input,
  .el-select {
    width: 300px;
  }

  .item {
    width: 30%;
    /* 100% / 3 = 33%，留一点空间避免浏览器计算误差 */
    margin-bottom: 30px;
    font-size: 16px;
    color: #707070;
    margin-right: 30px;
  }

}

.submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 150px;
}
</style>
