<template>
  <div class="home" v-loading="loading">
    <div class="panel">
      <div class="panel_1">
        <div class="top">
          <span>新增用户</span>
          <span>今天</span>
        </div>
        <div class="bottom">
          <span class="left">{{ obj.todayUsers }}</span>
          <span class="right">
            用户总数量：
            <span class="num">{{ obj.totalUsers }}</span>
          </span>
        </div>
      </div>
      <div class="panel_2">
        <div class="top">
          <span>新增订单</span>
          <span>今天</span>
        </div>
        <div class="bottom">
          <span class="left">{{ obj.todayOrders }}</span>
          <span class="right">
            订单总数量：
            <span class="num">{{ obj.totalOrders }}</span>
          </span>
        </div>
      </div>
      <div class="panel_3">
        <div class="top">
          <span>新增营业额</span>
          <span>今天</span>
        </div>
        <div class="bottom">
          <span class="left">
            <span class="yuan">￥</span>
            {{ obj.todayAmounts }}
          </span>
          <span class="right">
            营业额总额：
            <span class="yuan">￥</span>
            <span class="num">{{ obj.totalAmounts }}</span>
          </span>
        </div>
      </div>
    </div>

    <div class="charts" v-loading="loadingcharts">
      <div class="datetime">
        <span class="label">日期筛选</span>
        <el-date-picker v-model="daterange" type="daterange" align="right" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :clearable="false" :picker-options="pickerOptions" @change="onFilter"></el-date-picker>
      </div>
      <!-- 商品趋势图表 -->
      <div class="product_chart">
        <div class="echarts">
          <div class="echart" id="productChart" style="width: 100%; height: 550px"></div>
        </div>
      </div>
      <!-- 用户趋势图表 -->
      <div class="user_chart">
        <div class="echarts">
          <div class="echart" id="userChart" style="width: 100%; height: 550px"></div>
        </div>
      </div>
      <!-- 订单趋势图表 -->
      <div class="order_chart">
        <div class="echarts">
          <div class="echart" id="orderChart" style="width: 100%; height: 550px"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts"
export default {
  data() {
    return {
      loading: false,
      loadingcharts: false,
      obj: {},

      dateList: [],
      userList: [],
      orderList: [],
      amountList: [],

      chart1: null,
      chart2: null,
      chart3: null,

      yAxis: { type: "value" },

      daterange: [],
      pickerOptions: {
        // 不能选择超过30天的范围
        disabledDate(time) {
          const endDate = new Date()
          const startDate = new Date()
          startDate.setTime(startDate.getTime() - 31 * 24 * 60 * 60 * 1000)
          return time.getTime() > endDate.getTime() || time.getTime() < startDate.getTime()
        },
      },
    }
  },
  created() {
    this.getTotal()
  },
  mounted() {
    this.setDefaultDateRange()
    this.getChartsList()
  },
  methods: {
    setDefaultDateRange() {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 30 * 24 * 60 * 60 * 1000)
      this.daterange = [this.$dayjs(start).format("YYYY-MM-DD"), this.$dayjs(end).format("YYYY-MM-DD")]
    },
    onFilter(e) {
      this.chart1.clear()
      this.chart2.clear()
      this.chart3.clear()
      this.daterange = [this.$dayjs(e[0]).format("YYYY-MM-DD"), this.$dayjs(e[1]).format("YYYY-MM-DD")]
      this.getChartsList()
    },
    getTotal() {
      this.loading = true
      this.$axios
        .get(this.$api.homeToday)
        .then(res => {
          if (res.data.code == 100) {
            this.obj = res.data.result
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    getChartsList() {
      this.loadingcharts = true
      this.$axios
        .get(this.$api.homeList, {
          params: {
            startTime: this.daterange[0],
            endTime: this.daterange[1],
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            let { result } = res.data
            this.dateList = result.map(e => e.date)
            this.userList = result.map(e => e.users)
            this.amountList = result.map(e => e.amounts)
            this.orderList = result.map(e => e.orders)
            this.initProductChart()
            this.initUserChart()
            this.initOrderChart()
          }
          this.loadingcharts = false
        })
        .catch(() => {
          this.loadingcharts = false
        })
    },
    initProductChart() {
      this.chart1 = echarts.init(document.querySelector("#productChart"))
      this.setProductChartOption()
    },
    initUserChart() {
      this.chart2 = echarts.init(document.querySelector("#userChart"))
      this.setUserChartOption()
    },
    initOrderChart() {
      this.chart3 = echarts.init(document.querySelector("#orderChart"))
      this.setOrderChartOption()
    },
    // 营业额趋势图表数据
    setProductChartOption() {
      let option = {
        grid: { x: 50, x2: 25 },
        tooltip: { trigger: "axis" },
        xAxis: { type: "category", data: this.dateList },
        title: { text: "新增营业额数据趋势", x: "center" },
        legend: { bottom: "0", left: "center" },
        yAxis: this.yAxis,
        series: [{ name: "营业额", data: this.amountList, type: "line", smooth: true, itemStyle: { normal: { color: "#069BBC", lineStyle: { color: "#069BBC" } } } }],
      }
      option && this.chart1.setOption(option)
    },
    // 用户趋势图表数据
    setUserChartOption() {
      let option = {
        grid: { x: 50, x2: 25 },
        tooltip: { trigger: "axis" },
        xAxis: { type: "category", data: this.dateList },
        title: { text: "新增用户数据趋势", x: "center" },
        legend: { bottom: "0", left: "center" },
        yAxis: this.yAxis,
        series: [{ name: "用户数量", data: this.userList, type: "line", smooth: true, itemStyle: { normal: { color: "#FEBB80", lineStyle: { color: "#FEBB80" } } } }],
      }
      option && this.chart2.setOption(option)
    },
    // 订单趋势图表数据
    setOrderChartOption() {
      let option = {
        grid: { x: 50, x2: 25 },
        tooltip: { trigger: "axis" },
        xAxis: { type: "category", data: this.dateList },
        title: { text: "新增订单数据趋势", x: "center" },
        legend: { bottom: "0", left: "center" },
        yAxis: this.yAxis,
        series: [{ name: "订单数", data: this.orderList, type: "line", smooth: true, itemStyle: { normal: { color: "#FF9CD1", lineStyle: { color: "#FF9CD1" } } } }],
      }
      option && this.chart3.setOption(option)
    },
  },
}
</script>

<style scoped lang="scss">
.home {
  width: 1640px;
  min-height: 500px;
  padding: 40px 30px;
  margin: 0 auto;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  background-color: #fff;
  box-sizing: border-box;

  .panel {
    display: flex;
    justify-content: space-between;

    .panel_1,
    .panel_2,
    .panel_3 {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 508px;
      height: 130px;
      color: #fff;
      padding: 20px 30px;
      border-radius: 10px;
      background-position: 100%;
      background-size: cover;
      box-sizing: border-box;

      .top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        span:nth-child(1) {
          font-size: 16px;
        }

        span:nth-child(2) {
          font-size: 14px;
        }
      }

      .bottom {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .left {
          font-size: 46px;
          font-weight: bold;
        }

        .right {
          font-size: 16px;

          .num {
            font-size: 26px;
            font-weight: bold;
          }
        }
      }

      .yuan {
        font-size: 14px !important;
        font-weight: bold;
      }
    }

    .panel_1 {
      background: url(../assets/img/home/panel1.svg) no-repeat;
    }

    .panel_2 {
      background: url(../assets/img/home/panel2.svg) no-repeat;
    }

    .panel_3 {
      background: url(../assets/img/home/panel3.svg) no-repeat;
    }
  }

  .charts {
    margin-top: 50px;

    .datetime {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .label {
        color: #001423;
        font-size: 18px;
        margin-right: 24px;
      }

      /deep/ .el-date-editor {
        background-color: #f2f2f2;
        border: none;
        border-radius: 10px;
        margin-left: 20px;
      }

      /deep/.el-range-input {
        background-color: #f2f2f2;
      }
    }

    .product_chart,
    .user_chart,
    .order_chart {
      margin-top: 50px;
    }
  }
}
</style>
