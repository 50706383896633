/**
 * 营销管理api
 */
export default {
  // 会员管理
  shopMemberBillOnlineOrdersInfo: "/shopMember/billOnlineOrdersInfo", // 查询会员线上/线下订单详情
  shopMemberBillPageList: "/shopMember/billPageList", // 账单明细列表
  shopMemberList: "/shopMember/pageList", // 会员列表
  shopMemberRechargePageList: "/shopMember/rechargePageList", // 充值记录列表
  // 充值优惠
  deleteShopRechargeActive: "/shopRechargeActive/deleteShopRechargeActive", // 删除/失效
  insertShopRechargeActive: "/shopRechargeActive/insertShopRechargeActive", // 新增
  selectShopRechargeActive: "/shopRechargeActive/selectShopRechargeActive", // 列表

  // 优惠券
  couponList: "/coupon/list", //  优惠券管理->优惠券列表
  couponRecordList: "/coupon/recordList", //  优惠券管理->领券列表
  couponDelete: "/coupon/delete", // 删除优惠券/{id}
  couponSave: "/coupon/save", // 新增优惠券
  couponSelectInfo: "/coupon/select_info", // 查询优惠券详情/{id}
  couponUpdate: "/coupon/update", // 修改优惠券
  couponupdateStatus: "/coupon/updateStatus", // 修改优惠券状态
}
