import { render, staticRenderFns } from "./onlineOrder.vue?vue&type=template&id=01848aed&scoped=true"
import script from "./onlineOrder.vue?vue&type=script&lang=js"
export * from "./onlineOrder.vue?vue&type=script&lang=js"
import style0 from "./onlineOrder.vue?vue&type=style&index=0&id=01848aed&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01848aed",
  null
  
)

export default component.exports