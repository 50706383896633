<template>
  <div class="req_detail">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-button class="back" type="primary" plain @click="$router.go(-1)">返回</el-button>

        <div class="right">
          <el-input v-model="productName" placeholder="搜索商品名" suffix-icon="el-icon-search" @change="onSearch"
            clearable></el-input>
          <el-input v-model="barCode" placeholder="搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch"
            clearable></el-input>
          <!-- <el-select v-model="isNew" placeholder="是否新品" @change="onSearch" clearable>
            <el-option label="否" value="0"></el-option>
            <el-option label="是" value="1"></el-option>
          </el-select> -->
          <el-select v-model="auditStatus" placeholder="审核状态" @change="onSearch" clearable>
            <el-option label="待审核" value="0"></el-option>
            <el-option label="审核通过" value="1"></el-option>
            <el-option label="审核不通过" value="2"></el-option>
          </el-select>
        </div>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <!-- <el-table-column show-overflow-tooltip prop="serialId" label="商品编号" width="120"></el-table-column> -->
          <el-table-column show-overflow-tooltip prop="barCode" label="商品条形码" width="160"></el-table-column>
          <el-table-column show-overflow-tooltip prop="productName" label="商品名称" min-width="150"></el-table-column>
          <el-table-column show-overflow-tooltip prop="specs" label="规格" width="120"></el-table-column>
          <el-table-column show-overflow-tooltip prop="categoryName" label="商品类目" width="150"></el-table-column>
          <el-table-column show-overflow-tooltip prop="enterBox" label="入箱数" width="100"></el-table-column>
          <el-table-column show-overflow-tooltip prop="factoryName" label="厂家" width="150"></el-table-column>
          <el-table-column show-overflow-tooltip prop="brand" label="品牌" width="120"></el-table-column>
          <el-table-column show-overflow-tooltip prop="validityPeriod" label="保质期(天)" width="120"></el-table-column>
          <el-table-column show-overflow-tooltip prop="costPrice" label="进货价(元)" width="120"></el-table-column>
          <el-table-column show-overflow-tooltip prop="suggestPrice" label="零售价(元)" width="150"></el-table-column>
          <el-table-column show-overflow-tooltip prop="inventoryCount" label="初始库存数" width="120"></el-table-column>
          <!-- <el-table-column show-overflow-tooltip prop="" label="是否新品" width="80">
            <template slot-scope="scope">
              <span v-if="scope.row.isNew == 0">否</span>
              <span v-if="scope.row.isNew == 1">是</span>
            </template>
          </el-table-column> -->
          <el-table-column show-overflow-tooltip prop="" label="审核状态" width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.auditStatus == 0">待审核</span>
              <span v-if="scope.row.auditStatus == 1">审核通过</span>
              <span v-if="scope.row.auditStatus == 2">审核不通过</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="操作" header-align="center" align="center" fixed="right"
            width="120">
            <template slot-scope="scope">
              <el-button v-if="scope.row.auditStatus == 2" type="primary" plain
                @click="handleShow(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>

    <el-dialog title="查看" :visible.sync="dialogVisible" width="800px">
      <el-form ref="form" label-width="150px">
        <el-form-item label="审核不通过原因">
          <el-input v-model="auditMark" type="textarea" :rows="5" disabled></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancel">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      dialogVisible: false,
      productName: "",
      barCode: "",
      isNew: "",
      auditStatus: "",
      auditMark: "",
      tableData: [],

      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      this.$axios
        .get(this.$api.shopInventoryApplydetail, {
          params: {
            id: this.$route.query.id,
            auditStatus: this.auditStatus,
            productName: this.productName,
            isNew: this.isNew,
            barCode: this.barCode,
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list
            this.totalItemsCount = res.data.result.totalCount
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    onCancel() {
      this.dialogVisible = false
    },
    handleShow(row) {
      this.auditMark = row.auditMark
      this.dialogVisible = true
    },
    // 搜索
    onSearch() {
      this.tableData = []
      this.getList()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getList()
    },
  },
}
</script>

<style scoped lang="scss">
.req_detail {
  .search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    .el-input {
      width: 180px;
      margin-bottom: 20px;
    }

    .el-input,
    .el-select,
    .el-button {
      margin-left: 20px;
      margin-bottom: 20px;
    }

    .back {
      margin-left: 0;
    }
  }
}
</style>
