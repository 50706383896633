// 库存管理

export default {
  /**
  *
* 库存明细
*/
  // 列表
  getInventoryDetailsList: '/inventoryDetails/listPage',
  /**
    *
  * 库存统计
  */
  // 列表
  getInventoryStatisticsList: '/inventory_statistics/begin_end_list',
  /**
    *
  * 库存预警通知
  */
  // 列表
  getWarningNoticeList: '/shopWarnMsg/searchInventoryWarnStat',
  // 详情列表
  getWarningNoticeSingleList: '/shopWarnMsg/searchInventoryWarn',
  // 补货 忽略
  deleteShopWarningMsg: '/shopWarnMsg/delete',
  // 补货供应商查询
  getSupplierByProductId: '/product_warn_set/getSupplierByProductId',
  // 单个补货
  purchaseCartSave: '/purchase_cart/save_purchase_cart',
  // 批量补货(新)
  purchaseCartSaveBatch: '/purchase_cart/bill_purchase',
  // 商超消息总数
  getShopWarnMsgTotalCount: '/shopWarnMsg/getTotalCount',
  /**
    *
  * 库存预警设置
  */
  // 商品列表(新)
  selectInventoryWarnConfigByShopProduct: '/inventoryWarnConfig/selectInventoryWarnConfigByShopProduct',
  // 品牌列表(新)
  selectInventoryWarnConfigByBrand: '/inventoryWarnConfig/selectInventoryWarnConfigByBrand',
  // 品类列表(新)
  selectInventoryWarnConfigByCategory: '/inventoryWarnConfig/selectInventoryWarnConfigByCategory',
  // 设置/批量设置(新)
  insertInventoryWarnConfig: '/inventoryWarnConfig/insertInventoryWarnConfig',
  // 列表
  getWarningSettingList: '/shopProduct/listPage',
  // 设置
  changeWarningSetting: '/shopProduct/warnSet',
  /**
  /**
    *
  * 保质期预警通知
  */
  // 列表
  getShopProductWarnList: '/product_warn_set/shop_product_warn_list',
  // 取消预警通知
  sendUpdateProductWarn: '/product_warn_set/update_product_warn',
  /**
    *
  * 保质期预警设置
  */
  // 列表
  getProductWarnSetListPage: '/product_warn_set/listPage',
  // 设置
  sendProductWarnSetWarningSettings: '/product_warn_set/warningSettings',
  // 默认设置查看
  sendProductWarnSelectById: '/product_warn_set/selectById',
  /**
    *
  * 盘存记录
  */
  // 列表 
  getInventoryCheckList: '/inventory_check/pageList',
  // 查看详情 
  getInventoryCheckDetailsList: '/inventory_check/details',
  // 导入 
  importInventoryCheck: '/inventory_check/importInventoryCheck',
  // 导出 
  exportInventoryCheck: '/inventory_check/exportInventoryCheck',
}