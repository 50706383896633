<template>
  <div class="main">
    <div>
      <div class="flex">
        <el-tabs v-model="activeName" type="card">
          <el-tab-pane :label="item.label" :name="item.name" v-for="(item, index) in tabs" :key="index"></el-tab-pane>
        </el-tabs>
        <el-button type="primary" @click="handleSet">批量设置</el-button>
      </div>

      <div v-if="activeName == 1">
        <productSetting ref="productSetting" @handleSelectionChange="handleSelectionChange"></productSetting>
      </div>
      <div v-if="activeName == 2">
        <brandSetting ref="brandSetting" @handleSelectionChange="handleSelectionChange"></brandSetting>
      </div>
      <div v-if="activeName == 3">
        <typeSetting ref="typeSetting" @handleSelectionChange="handleSelectionChange"></typeSetting>
      </div>
    </div>

    <!-- 批量设置弹窗 -->
    <el-dialog title="批量设置" :visible.sync="dialogVisible" @close="handleCloseDialog">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
        <h2>预警批量设置</h2>
        <el-row class="row-bottom">
          <el-col>
            <el-form-item label-width="250px" label="第一次预警：保质期剩余" prop="firstWarnDay">
              <el-input v-model="ruleForm.firstWarnDay" placeholder="请输入">
                <template slot="append">天</template>
              </el-input>
              <span class="left">时预警</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="row-bottom">
          <el-col>
            <el-form-item label-width="250px" label="第二次预警：保质期剩余" prop="secondWarnDay">
              <el-input v-model="ruleForm.secondWarnDay" placeholder="请输入">
                <template slot="append">天</template>
              </el-input>
              <span class="left">时预警</span>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import productSetting from './components/productSetting.vue';
import brandSetting from './components/brandSetting.vue';
import typeSetting from './components/typeSetting.vue';
export default {
  components: {
    productSetting,
    brandSetting,
    typeSetting
  },
  data() {
    return {
      activeName: "1",
      tabs: [{
        label: "按商品设置",
        name: "1"
      },
      {
        label: "按品牌设置",
        name: "2"
      },
      {
        label: "按品类设置",
        name: "3"
      },
      ],
      // 弹窗数据
      dialogVisible: false, // 弹窗显隐
      ruleForm: {
        firstWarnDay: null,
        secondWarnDay: null,

      },
      rules: {
        firstWarnDay: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        },
        {
          pattern: /^\d+$/,
          message: '只能输入数字',
          trigger: 'blur'
        }
        ],
        secondWarnDay: [{
          required: true,
          message: '请输入',
          trigger: 'blur'
        },
        {
          pattern: /^\d+$/,
          message: '只能输入数字',
          trigger: 'blur'
        },
        {
          validator: (rule, value, callback) => {
            const firstWarnDayValue = this.ruleForm.firstWarnDay;
            if (firstWarnDayValue && value && parseInt(value) > parseInt(firstWarnDayValue)) {
              callback(new Error('第二次预警天数不能大于第一次预警天数'));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }
        ]
      },
      selectedItems: [],
    }
  },

  created() {

  },
  methods: {
    handleSelectionChange(val) {
      this.selectedItems = val;
    },
    // 打开设置
    handleSet() {
      if (this.selectedItems.length == 0) {
        this.$message.warning("请先选择！")
        return
      }
      this.dialogVisible = true;
    },
    // 关闭弹窗
    handleCloseDialog() {
      this.dialogVisible = false;
      this.resetForm();
    },
    // 表单校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          console.log('error submit!!');
          return false;
        } else {
          let bizIdList;
          if (this.activeName == 1) {
            bizIdList = this.selectedItems.map(item => item.productId);
          } else if (this.activeName == 2 || this.activeName == 3) {
            bizIdList = this.selectedItems.map(item => item.bizId);
          }
          const bizType = this.activeName === '1' ? 1 : this.activeName === '2' ? 2 : 3;

          // 提交请求
          this.$axios.post(this.$api.setExpireWarning, {
            bizIdList: bizIdList,
            bizType: bizType, //1商品；2品牌；3分类
            bizUserId: this.$store.state.userInfo.shopId, // 业务id
            bizUserType: 1, // 1商超；2供应商；3总后台
            firstWarnDay: Number(this.ruleForm.firstWarnDay), // 第一次预警
            secondWarnDay: Number(this.ruleForm.secondWarnDay), // 第二次预警
          }).then((res) => {
            if (res.data.code == 100) {
              this.$message.success('设置成功!');
              if (this.activeName == 1) {
                this.$refs.productSetting.getExpireWarningListByProduct();
              }
              if (this.activeName == 2) {
                this.$refs.brandSetting.getExpireWarningListByBrand();
              }
              if (this.activeName == 3) {
                this.$refs.typeSetting.getExpireWarningListByCategory();
              }
              this.handleCloseDialog();
            }
          });
        }
      });
    },
    // 重置表单
    resetForm() {
      this.ruleForm = {};
      this.$refs.ruleForm.resetFields();
    },

  },
}
</script>

<style lang="scss" scoped>
.main {

  // padding: 20px 36px 30px;
  ::v-deep .el-tabs {
    margin: 30px 0px 0px 30px;
  }

  ::v-deep .el-tabs--card>.el-tabs__header {
    border-bottom: none !important;
  }

  ::v-deep .el-tabs__item {
    color: #707070 !important;
  }

  ::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item {}

  ::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border: 1px solid #e2e2e2;
  }

  ::v-deep .el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    background: #069bbc;
    color: #ffffff !important;
    border-bottom: none !important;
  }

  ::v-deep .el-tabs__item:hover {
    color: #707070 !important;
  }

  .flex {
    display: flex;
    align-items: center;
  }

  .el-button {
    margin-top: 15px;
    margin-left: 15px;
  }

  .search-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // flex-wrap: wrap;

    .input-box {
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }

  h2 {
    padding-left: 150px;
    margin: 20px 0;
  }

  .row-bottom {

    /deep/.el-input,
    /deep/.el-input__inner {
      width: 255px;
    }

    /deep/ .el-form-item__label {
      color: #272727;
      font-size: 16px;
      padding-right: 30px;
    }

    /deep/.el-input__inner {
      background: #f2f2f2;
      border-radius: 10px 0 0 10px;
      border: none;
    }

    /deep/ .el-input-group__append {
      background: #f2f2f2;
      border-radius: 0px 10px 10px 0;
      border: none;
      color: #000;
    }

    .left {
      color: #272727;
      font-size: 16px;
      margin-left: 20px;
    }
  }
}
</style>
