<!-- 对账单 -->

<template>
  <div class="main">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="supplierName" placeholder="搜索供应商名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-date-picker v-model="dataValue" type="daterange" popper-class="sift-data" range-separator="至"
          start-placeholder="期初日期" end-placeholder="期末日期" @change="handleSelectData" clearable
          value-format="yyyy-MM-dd"></el-date-picker>
      </div>
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="supplierName" label="供应商名称"></el-table-column>
          <el-table-column min-width="170" show-overflow-tooltip prop="initialPaymentAmount"
            label="期初贷款金额(元)"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="paymentAmount" label="贷款金额(元)"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="settlementAmount"
            label="结算金额(元)"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="endSettlementAmount"
            label="期末结算金额(元)"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="200" fixed="right">
            <template slot-scope="scope">
              <el-button size="small" class="btn" type="primary" plain @click="handleCheck(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      supplierName: "",
      dataValue: "", // 时间区间
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getShopPurchaseStat()
  },
  methods: {
    // 获取列表
    getShopPurchaseStat() {
      this.loading = true
      let params = {
        supplierName: this.supplierName || null,
        shopId: this.$store.state.userInfo.shopId, //shopId
        page: this.currentPage,
        pageSize: this.pageSize,
      }

      // 时间区间
      if (this.dataValue && this.dataValue.length === 2) {
        params.startTime = this.dataValue[0]
        params.endTime = this.dataValue[1]
      } else {
        params.startTime = null
        params.endTime = null
      }

      this.$axios
        .get(this.$api.getShopPurchaseStat, { params })
        .then(res => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list
            this.totalItemsCount = res.data.result.totalCount
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 查看
    handleCheck(row) {
      this.$router.push({ path: "/business/productStatement", query: { supplierName: row.supplierName, startTime: this.dataValue[0] || '', endTime: this.dataValue[1] || '' } })
    },
    // 清空
    clearData() {
      this.tableData = []
      this.currentPage = 1
    },
    // 搜索
    onSearch() {
      this.clearData()
      this.getShopPurchaseStat()
    },
    // 时间搜索
    handleSelectData(value) {
      this.dataValue = value
      this.onSearch()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e
      this.onSearch()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getShopPurchaseStat()
    },
  },
}
</script>

<style scoped lang="scss">
.main {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    .el-input {
      width: 200px;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    .el-select {
      margin-left: 20px;
      margin-bottom: 20px;
    }

    /deep/ .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    /deep/.el-range-input {
      background-color: #f2f2f2;
    }
  }
}
</style>
