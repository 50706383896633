/**
 * 商品管理api
 */
export default {
  /**
   * 标准商品
   */
  // 列表
  getShopProductList: "/shopProduct/listPage",
  // 查看详情
  getShopProductDetailById: "/shopProduct/byId",
  // 上下架
  changeMallStatus: "/shopProduct/mallStatus",
  // 修改
  updateProduct: "/shopProduct/update",
  // 编辑图片
  updateShopProductPic: "/shopProduct/updateShopProductPic",

   /**
   * 非标商品
   */
  // 列表
  getShopLabelProductList: "/shopLabelProduct/list",
  // 查看详情
  getShopLabelProductDetail: "/shopLabelProduct/detail",
  // 新增
  saveShopLabelProduct: "/shopLabelProduct/save",
  // 删除
  deleteShopLabelProduct: "/shopLabelProduct/delete",
  // 导出
  exportExcelShopLabelProduct: "/shopLabelProduct/exportExcel",


  /**
   * 新品管理
   */
  // 列表
  getProductApplyList: "/productApply/list",
  // 申请新商品
  AddProductInfoSupplierList: "/productApply/save",
  // 详情
  getProductInfoSupplierDetail: "/productApply/details",
  // 编辑 重新申请
  UpdateProductInfoSupplierList: "/productApply/update",
  // 批量
  importNewProductApply: "/productApply/importNewProductApply",

  /**
   * 分类列表
   */
  // 列表
  getCategoryInfoList: "/categoryInfo/list",

  /**
   * 运费模板
   */
  // 运费模板-列表
  getFreightTemplateList: "/freightTemplate/list",
  // 删除运费模板
  deleteFreightTemplate: "/freightTemplate/delete",
  // 新增运费模板
  saveFreightTemplate: "/freightTemplate/save",
  // 修改详情
  queryFreightTemplateById: "/freightTemplate/byId",
  // 修改运费模板
  updateFreightTemplate: "/freightTemplate/update",
  // 多商品运费设置
  addFreightTypeSet: "/freightTemplate/addFreightTypeSet",
  // 多商品运费设置-详情
  freightTypeSetDetail: "/freightTemplate/freightTypeSetDetail",
  // 关联商品-列表
  getReleatedProductList: "/freightTemplate/productList",
  // 关联商品-新增商品列表
  getFreightProductList: "/freightTemplate/freightProductList",
  // 新增商品
  addFreightProduct: "/freightTemplate/addFreightProduct",
  // 删除关联商品
  deleteFreightTemplateProduct: "/freightTemplate/deleteProduct/",

  /**
   * 费用设置
   */
  // 费用设置-列表
  getShopFeeSetList: "/shopFeeSet/list",
  // 修改金额
  updateShopFeeAmount: "/shopFeeSet/updateShopFeeAmount",
  // 修改状态
  updateShopFeeStatus: "/shopFeeSet/updateShopFeeStatus",

  shopInventoryApplydetail: "/shopInventoryApply/detail", // 商品管理->库存申请->查看
  shopInventoryApplyimportShopInventoryApply: "/shopInventoryApply/importShopInventoryApply", // 商品管理->库存申请->导入
  shopInventoryApplypageList: "/shopInventoryApply/pageList", // 商品管理->库存申请

    /* 
    预警设置 
  */
   // 列表
  getExpireWarningListByCategory: "/expire_warn_config/shop_expire_warn_config_category_list", //  分类
  getExpireWarningListByBrand: "/expire_warn_config/shop_expire_warn_config_brand_list", // 品牌 
  getExpireWarningListByProduct: "/expire_warn_config/listPage", // 商品
  setExpireWarning: "/expire_warn_config/shop_set_up_expire_warn_config", // 设置
}
