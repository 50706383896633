<!-- 查看详情 -->
<template>
  <div class="info" v-loading="loading">
    <back />

    <el-form :model="ruleForm" ref="ruleForm" label-width="155px" class="demo-ruleForm">
      <div class="form">
        <div class="title">基本信息:</div>
        <div class="container">
          <div class="item">PLU编号：{{ ruleForm?.pluNum }}</div>
          <div class="item">商品名称：{{ ruleForm?.productName }}</div>
          <div class="item">商品编码：{{ ruleForm?.productCode }}</div>
          <div class="item">保质期(天)：{{ ruleForm?.validityDay }}</div>
          <div class="item">模式：
            <span v-if="ruleForm?.modeType == 0">称重</span>
            <span v-if="ruleForm?.modeType == 1">计件</span>
          </div>
          <div class="item">规格（件）：{{ ruleForm?.specsUnit }}</div>
          <div class="item">单价（元）：{{ ruleForm?.salePrice }}</div>
        </div>
      </div>
    </el-form>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      productId: '',
      ruleForm: {},
    }
  },
  created() {
    this.productId = this.$route.query.productId;
    this.getShopLabelProductDetail();
  },
  methods: {
    getShopLabelProductDetail() {
      this.loading = true;
      this.$axios.get(`${this.$api.getShopLabelProductDetail}/${this.productId}`).then((res) => {
        if (res.data.code == 100) {
          this.ruleForm = res.data.result
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
  },

}
</script>

<style lang="scss" scoped>
.info {

  // 1.基本信息
  .form {
    margin: 30px 20px;

    .title {
      font-size: 20px;
      margin-bottom: 30px;
      font-weight: bold;
    }

    .container {
      display: flex;
      flex-wrap: wrap;
    }

    .item {
      width: 30%;
      margin-bottom: 30px;
      font-size: 16px;
      color: #707070;
      margin-right: 30px;
    }
  }

}
</style>
