<template>
  <div class="list">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-input v-model="name" placeholder="请输入优惠券名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-select v-model="type" placeholder="请选择类型" @change="onChange" clearable>
          <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-select v-model="status" placeholder="请选择状态" @change="onChange" clearable>
          <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-button type="primary" @click="handleShow(null, '新增')">新增</el-button>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="couponCode" label="优惠券编号"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="couponName" label="优惠券名称"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="100" label="余额/总额">
            <template slot-scope="scope">{{ scope.row.availableCount }}/{{ scope.row.totalCount }}</template>
          </el-table-column>
          <el-table-column show-overflow-tooltip min-width="80" label="类型">
            <template slot-scope="scope">
              <span>{{ mapCouponType(scope.row.couponType) }}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip min-width="80" label="适用商品">
            <template slot-scope="scope">
              <span>{{ scope.row.productType == "1" ? "全场通用" : "指定商品" }}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="orderAmount" label="订单金额(元)" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="preferentialAmount" label="优惠金额(元)" align="center"></el-table-column>
          <!-- <el-table-column label="折扣率"  align="center">
            <template slot-scope="scope">
              <span> {{ setDiscountRate(scope.row.discountRate) }}</span>
            </template>
          </el-table-column> -->
          <el-table-column show-overflow-tooltip min-width="120" prop="discountRate" label="折扣率" align="center"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="210" label="有效期限">
            <template slot-scope="scope">
              <span>{{ scope.row.startDate }} 至 {{ scope.row.endDate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <span :style="{ color: scope.row.effectiveStatus == '3' ? '#EB2500' : '#68706E' }">{{ mapEffectiveStatus(scope.row.effectiveStatus) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="是否启用">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.switch" active-color="#069BBC" inactive-color="#E6E6E6" @change="changeStatus(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="createdTime" label="创建时间" width="170"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="220" fixed="right">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" size="" plain @click="handleShow(scope.row.id, '查看')">查看</el-button>
              <el-button class="btn" type="" size="" plain @click="handleShow(scope.row.id, '修改')">修改</el-button>
              <el-button class="btn" type="danger" size="" plain @click="handleDel(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      name: "",
      type: "",
      status: "",
      options1: [
        { value: "1", label: "满减券" },
        { value: "2", label: "折扣" },
        // { value: '3', label: '代金券' },
      ],
      options2: [
        { value: "1", label: "已生效" },
        { value: "2", label: "未生效" },
        { value: "3", label: "已过期" },
      ],
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getCouponList()
  },
  methods: {
    mapCouponType(val) {
      return this.options1.find(e => e.value == val).label || ""
    },
    mapEffectiveStatus(val) {
      return this.options2.find(e => e.value == val).label || ""
    },
    setDiscountRate(val) {
      let per = val
      if (per) return `${val * 100}%`
      return "-"
    },
    // 切换状态
    changeStatus(row) {
      const originalSwitch = row.switch
      this.$confirm(`该优惠券即将${row.switch ? "启用" : "停用"}，是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .post(this.$api.couponupdateStatus, {
              id: row.id,
              couponStatus: row.switch ? "1" : "3",
            })
            .then(() => {
              this.$set(row, "switch", originalSwitch)
              this.$message.success("操作成功")
              this.getCouponList()
            })
            .catch(() => {
              this.$set(row, "switch", !originalSwitch)
              this.$message.error("操作失败")
              this.getCouponList()
            })
        })
        .catch(() => {
          this.$set(row, "switch", !originalSwitch)
        })
    },
    handleDel(e) {
      this.$confirm("即将删除该优惠券, 是否继续?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios.post(`${this.$api.couponDelete}/${e.id}`).then(res => {
            if (res.data.code == 100) {
              this.$message.success("删除成功")
              this.getCouponList()
            }
          })
        })
        .catch(() => {})
    },
    handleShow(id, sign) {
      this.$router.push({ name: "couponDetail", query: { id, sign } })
    },
    getCouponList() {
      this.loading = true
      this.$axios
        .get(this.$api.couponList, {
          params: {
            couponName: this.name,
            couponType: this.type,
            effectiveStatus: this.status,
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.tableData =
              res.data.result.list.map(e => {
                return {
                  ...e,
                  switch: e.couponStatus == 1 ? true : false,
                  preferentialAmount: e.couponType == 1 ? e.reductionAmount : e.couponType == 3 ? e.fixedAmount : "-",
                }
              }) || []
            this.totalItemsCount = res.data.result.totalCount
            this.loading = false
          } else {
            this.loading = false
          }
        })
    },
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getCouponList()
    },
    onChange() {
      this.tableData = []
      this.currentPage = 1
      this.getCouponList()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getCouponList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getCouponList()
    },
  },
}
</script>

<style scoped lang="scss">
.list {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 200px;

      /deep/ .el-input__inner {
        border-radius: 10px;
      }
    }

    .el-input,
    .el-select,
    .el-button {
      margin-left: 20px;
    }
  }
}
</style>
