<!-- 全部商品 -->

<template>
  <div class="all">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="barCode" placeholder="请搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-select v-model="shelfValue" placeholder="是否上架" @change="handleSelect" clearable>
          <el-option v-for="item in shelfOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-cascader v-model="categoryValue" :options="categoryListData" placeholder="商品类目" :props="{ expandTrigger: 'hover', label: 'categoryName', value: 'categoryId', emitPath: false, checkStrictly: true }" @change="onSearch" clearable></el-cascader>
        <el-input v-model="factoryValue" placeholder="请搜索厂家" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="productValue" placeholder="请搜索商品名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <!-- <el-input v-model="supplyValue" placeholder="请搜索供应商名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input> -->
        <el-button class="btn" type="primary" @click="batchChangeStatus('down')">批量下架</el-button>
        <el-button class="btn" type="primary" @click="batchChangeStatus('up')">批量上架</el-button>
      </div>

      <!-- 表格 -->
      <div class="globle_table" style="max-height: 600px; overflow: auto">
        <el-table :data="tableData" style="width: 100%" max-height="540" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" fixed="left"></el-table-column>
          <el-table-column label="序号" width="80" show-overflow-tooltip fixed="left">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <!-- <el-table-column label="图片" width="110" fixed="left">
            <template slot-scope="scope">
              <span class="show_img" @click="previewImage(scope.row.picUrlList)">预览图片</span>
            </template>
          </el-table-column> -->
          <el-table-column label="图片" width="100" align="center">
            <template slot-scope="scope">
              <el-image class="busilicense" :src="scope.row.picUrlList && scope.row.picUrlList.length > 0 ? scope.row.picUrlList[0] : ''" fit="cover" @mouseover="previewImage(scope.row.picUrlList)"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="productName" label="商品名称" show-overflow-tooltip min-width="140" fixed="left"></el-table-column>
          <el-table-column prop="specs" label="规格" show-overflow-tooltip min-width="100" fixed="left"></el-table-column>
          <el-table-column prop="brand" label="品牌" show-overflow-tooltip min-width="100"></el-table-column>
          <el-table-column prop="salePrice" label="零售价(元)" show-overflow-tooltip min-width="130"></el-table-column>
          <el-table-column prop="supplyPrice" label="进货价(元)" show-overflow-tooltip min-width="130"></el-table-column>
          <el-table-column prop="grossMargin" label="毛利率" show-overflow-tooltip min-width="130">
            <template slot-scope="scope">
              <span v-if="scope.row.grossMargin != null">{{ `${scope.row.grossMargin}%` }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="supplyPrice" label="供货价(元)" show-overflow-tooltip min-width="120"></el-table-column> -->
          <el-table-column prop="validityPeriod" label="保质期(天)" show-overflow-tooltip min-width="140"></el-table-column>
          <!-- <el-table-column prop="shopName" label="店铺名称" show-overflow-tooltip min-width="140"></el-table-column> -->
          <!-- <el-table-column prop="supplierName" label="供应商名称" show-overflow-tooltip min-width="140"></el-table-column> -->
          <!-- <el-table-column prop="produceDate" label="生产月份" show-overflow-tooltip min-width="140"></el-table-column> -->
          <!-- <el-table-column prop="serialId" label="商品编号" show-overflow-tooltip min-width="140"></el-table-column> -->
          <el-table-column prop="barCode" label="商品条形码" show-overflow-tooltip min-width="140"></el-table-column>
          <el-table-column prop="categoryName" label="商品类目" show-overflow-tooltip min-width="140"></el-table-column>
          <el-table-column prop="enterBox" label="入箱数" show-overflow-tooltip min-width="100"></el-table-column>
          <el-table-column prop="factoryName" label="厂家" show-overflow-tooltip min-width="140"></el-table-column>
          <el-table-column label="状态" header-align="center" align="center" min-width="100">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.switch" active-color="#069BBC" inactive-color="#E2E2E2" @change="changeStatus(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="210" fixed="right">
            <template slot-scope="scope">
              <el-button size="small" class="btn" type="primary" plain @click="handleCheck(scope.row)">查看</el-button>
              <el-button size="small" class="btn" type="primary" plain @click="handleModify(scope.row)">修改</el-button>
              <!-- <el-button class="btn" type="primary" plain @click="handleOpenPicDialog(scope.row)">编辑</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>

    <!-- 编辑图片 -->
    <el-dialog title="编辑" :visible.sync="picDialogVisible" :before-close="onCancelPicDialog">
      <el-form :model="picRuleForm" :rules="picRules" ref="picRuleForm" label-width="150px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品名称" prop="productName">
              <el-input v-model="picRuleForm.productName" placeholder="自动带入" readonly></el-input>
            </el-form-item>

            <el-form-item class="item logo_pic" label="商品图片" prop="totalPic">
              <div class="pic-row">
                <div class="pic">
                  <el-upload class="logo_uploader" accept=".png,.jpeg,.jpg" name="multipartFile" :action="action" :show-file-list="false" :on-success="handleSuccess1" :before-upload="beforeUploads" :on-remove="handleRemoveLogo1" :disabled="picRuleForm.picUrl1 ? true : false">
                    <img v-if="picRuleForm.picUrl1" :src="picRuleForm.picUrl1" class="logoimg" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <i v-if="picRuleForm.picUrl1" class="delpic delpic1 el-icon-error" @click="handleRemoveLogo1"></i>
                </div>
                <div class="pic">
                  <el-upload class="logo_uploader" accept=".png,.jpeg,.jpg" name="multipartFile" :action="action" :show-file-list="false" :on-success="handleSuccess2" :before-upload="beforeUploads" :on-remove="handleRemoveLogo2" :disabled="picRuleForm.picUrl2 ? true : false">
                    <img v-if="picRuleForm.picUrl2" :src="picRuleForm.picUrl2" class="logoimg" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <i v-if="picRuleForm.picUrl2" class="delpic delpic2 el-icon-error" @click="handleRemoveLogo2"></i>
                </div>
                <div class="pic">
                  <el-upload class="logo_uploader" accept=".png,.jpeg,.jpg" name="multipartFile" :action="action" :show-file-list="false" :on-success="handleSuccess3" :before-upload="beforeUploads" :on-remove="handleRemoveLogo3" :disabled="picRuleForm.picUrl3 ? true : false">
                    <img v-if="picRuleForm.picUrl3" :src="picRuleForm.picUrl3" class="logoimg" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                  <i v-if="picRuleForm.picUrl3" class="delpic delpic3 el-icon-error" @click="handleRemoveLogo3"></i>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancelPicDialog">取 消</el-button>
        <el-button type="primary" @click="submitFormPic('picRuleForm')">确 定</el-button>
      </span>
    </el-dialog>

    <el-image-viewer
      v-if="showViewer"
      :on-close="
        () => {
          showViewer = false
        }
      "
      :url-list="imgList"
    ></el-image-viewer>
  </div>
</template>

<script>
export default {
  components: {
    "el-image-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      loading: false,
      shelfValue: "", // 是否上架
      shelfOptions: [
        {
          value: "1",
          label: "上架",
        },
        {
          value: "0",
          label: "下架",
        },
      ],
      categoryValue: "", // 请搜索商品类目
      factoryValue: "", // 请搜索厂家
      productValue: "", // 请搜索商品名称
      supplyValue: "", // 请搜索供应商名称
      barCode: "", // 条形码
      categoryListData: [],
      // 表格数据
      tableData: [],
      multipleSelection: [], // 选中数据

      // 图片预览
      showViewer: false,
      imgList: [],
      // 编辑图片
      picDialogVisible: false,
      picRuleForm: {
        productName: "",
        totalPic: "",
        picUrl1: "",
        picUrl2: "",
        picUrl3: "",
      },
      picRules: {
        totalPic: [
          {
            validator: () => {
              if (!this.picRuleForm.picUrl1 && !this.picRuleForm.picUrl2 && !this.picRuleForm.picUrl3) {
                return Promise.reject("请至少上传一张图片")
              } else {
                return Promise.resolve()
              }
            },
            trigger: "submit",
          },
        ],
      },
      action: this.$axios.defaults.baseURL + this.$api.uploadFile,
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    // 缓存页面的搜索条件
    let obj = JSON.parse(localStorage.getItem("allProduct"))
    this.shelfValue = obj?.shelfValue
    this.productValue = obj?.productValue
    this.categoryValue = obj?.categoryValue
    this.factoryValue = obj?.factoryValue
    this.currentPage = obj?.currentPage
    this.barCode = obj?.barCode
    this.$saveOrUpdateCache("allProduct", {})

    this.getShopProductList()
    this.getCategoryInfoList()
  },
  methods: {
    // 获取商品列表
    getShopProductList() {
      this.loading = true
      this.$axios
        .get(this.$api.getShopProductList, {
          params: {
            mallStatus: this.shelfValue || null, // 上下架状态
            categoryId: this.categoryValue || null, // 请搜索分类
            productName: this.productValue || null, // 搜索商品名称
            supplierName: this.supplyValue || null, // 请搜索供应商名称
            factoryName: this.factoryValue || null, // 请搜索厂家
            barCode: this.barCode || null, //
            shopId: this.$store.state.userInfo.shopId, // 商超id
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            let list = res.data.result.list.map(e => {
              return {
                ...e,
                switch: e.mallStatus == "1" ? true : false,
              }
            })
            this.tableData = list
            this.totalItemsCount = res.data.result.totalCount
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    getCategoryInfoList() {
      this.$axios
        .get(this.$api.getCategoryInfoList, {
          params: {
            auditStatus: "1",
            page: 1,
            pageSize: 9999,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.categoryListData = res.data.result.list
          }
          this.loading = false
        })
    },
    handleOpenPicDialog(row) {
      this.picDialogVisible = true
      this.$set(this.picRuleForm, "productName", row.productName)
      this.$set(this.picRuleForm, "productId", row.productId)
      if (row.picUrlList && Array.isArray(row.picUrlList)) {
        this.$set(this.picRuleForm, "picUrl1", row.picUrlList[0] || "")
        this.$set(this.picRuleForm, "picUrl2", row.picUrlList[1] || "")
        this.$set(this.picRuleForm, "picUrl3", row.picUrlList[2] || "")
      } else {
        this.$set(this.picRuleForm, "picUrl1", "")
        this.$set(this.picRuleForm, "picUrl2", "")
        this.$set(this.picRuleForm, "picUrl3", "")
      }
    },
    // 关闭对话框
    onCancelPicDialog() {
      this.picDialogVisible = false
      this.resetFormPic()
    },
    // 提交表单
    submitFormPic(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios
            .post(this.$api.updateShopProductPic, {
              productId: this.picRuleForm.productId,
              shopId: this.$store.state.userInfo.shopId,
              picUrlList: [this.picRuleForm.picUrl1 || "", this.picRuleForm.picUrl2 || "", this.picRuleForm.picUrl3 || ""],
            })
            .then(res => {
              if (res.data.code == 100) {
                this.$message.success(res.data.desc)
                this.getShopProductList()
                this.onCancelPicDialog()
              }
            })
            .catch(err => {
              this.$message.error(err.data.desc)
            })
        }
      })
    },
    // 重置校验
    resetFormPic() {
      this.$refs.picRuleForm.resetFields()
      this.picRuleForm = {}
    },
    handleRemoveLogo1() {
      this.picRuleForm.picUrl1 = ""
    },
    handleSuccess1(res) {
      if (res.code == 100) {
        this.picRuleForm.picUrl1 = res.result.fileUrl
      } else {
        this.$message({
          showClose: true,
          message: res.desc,
          type: "error",
        })
      }
    },
    handleRemoveLogo2() {
      this.picRuleForm.picUrl2 = ""
    },
    handleSuccess2(res) {
      if (res.code == 100) {
        this.picRuleForm.picUrl2 = res.result.fileUrl
      } else {
        this.$message({
          showClose: true,
          message: res.desc,
          type: "error",
        })
      }
    },
    handleRemoveLogo3() {
      this.picRuleForm.picUrl3 = ""
    },
    handleSuccess3(res) {
      if (res.code == 100) {
        this.picRuleForm.picUrl3 = res.result.fileUrl
      } else {
        this.$message({
          showClose: true,
          message: res.desc,
          type: "error",
        })
      }
    },
    beforeUploads(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png"
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isJPG) {
        this.$message.error("图文只支持jpg、png、jpeg，请调整格式再上传!")
      }
      if (!isLt5M) {
        this.$message.error("上传图片大小不能超过 5MB!")
      }
      return isJPG && isLt5M
    },

    // 选中
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    // 批量切换上下架状态
    batchChangeStatus(status) {
      if (this.multipleSelection.length === 0) {
        this.$message.warning("请先选择商品")
        return
      }
      const action = status === "up" ? "上架" : "下架"
      const ids = this.multipleSelection.map(item => item.id)

      this.$confirm(`确定批量${action}这些商品吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .put(this.$api.changeMallStatus, {
              ids: ids,
              mallStatus: status === "up" ? "1" : "0",
            })
            .then(() => {
              // 批量操作成功后更新商品列表状态
              this.multipleSelection.forEach(item => {
                item.switch = status === "up" ? true : false
              })
              this.$message.success(`批量${action}操作成功`)
              this.getShopProductList()
            })
            .catch(() => {
              // this.$message.error(`批量${action}操作失败`)
              this.getShopProductList()
            })
        })
        .catch(() => {
          // 取消操作时恢复之前的选择状态
          this.multipleSelection.forEach(item => {
            item.switch = !item.switch
          })
          this.getShopProductList()
        })
    },
    // 切换上下架
    changeStatus(row) {
      const originalSwitch = row.switch
      this.$confirm(`确定${row.switch ? "上架" : "下架"}该商品吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$axios
            .put(this.$api.changeMallStatus, {
              ids: [row.id],
              mallStatus: row.switch ? "1" : "0",
            })
            .then(() => {
              this.$set(row, "switch", originalSwitch)
              this.$message.success("操作成功")
              this.getShopProductList()
            })
            .catch(() => {
              this.$set(row, "switch", !originalSwitch)
              // this.$message.error("操作失败")
              this.getShopProductList()
            })
        })
        .catch(() => {
          this.$set(row, "switch", !originalSwitch)
          this.getShopProductList()
        })
    },
    // 跳转
    handleCheck(row) {
      let obj = {
        shelfValue: this.shelfValue,
        productValue: this.productValue,
        categoryValue: this.categoryValue,
        factoryValue: this.factoryValue,
        barCode: this.barCode,
        currentPage: this.currentPage,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("allProduct", obj)
      this.$router.push({ path: "/product/detail/info", query: { id: row.id } })
    },
    handleModify(row) {
      let obj = {
        shelfValue: this.shelfValue,
        productValue: this.productValue,
        categoryValue: this.categoryValue,
        factoryValue: this.factoryValue,
        barCode: this.barCode,
        currentPage: this.currentPage,
      }
      // 缓存页面的搜索条件
      this.$saveOrUpdateCache("allProduct", obj)
      this.$router.push({ path: "/product/detail/modify", query: { id: row.id } })
    },
    // 清空
    clearData() {
      this.tableData = []
      this.currentPage = 1
    },
    // 下拉选择
    handleSelect(value) {
      this.clearData()
      this.shelfValue = value
      this.getShopProductList()
    },
    // 搜索
    onSearch() {
      this.clearData()
      this.getShopProductList()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData()
      this.pageSize = e
      this.getShopProductList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getShopProductList()
    },
    // 图片预览
    previewImage(url) {
      this.imgList = url
      this.showViewer = true
    },
  },
}
</script>

<style lang="scss" scoped>
.all {
  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    .el-input {
      width: 250px;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    .el-select,
    .el-cascader,
    .el-button {
      margin-left: 20px;
      margin-bottom: 20px;
    }

    ::v-deep .el-cascader {
      width: 250px;

      .el-input,
      .el-input__inner {
        width: 250px;
      }
    }
  }

  .show_img {
    color: #069bbc;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }

  .busilicense {
    display: block;
    width: 80px;
    height: 58px;
    border-radius: 4px;
  }

  // 图片
  .logo_pic {
    position: relative;

    ::v-deep .el-form-item__content {
      width: 100px;
      height: 100px;
    }

    .pic-row {
      display: flex;

      .pic {
        margin-right: 15px;

        .logo_uploader {
          ::v-deep .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
          }

          ::v-deep .el-upload:hover {
            border-color: #069bbc;
          }

          .avatar-uploader-icon {
            font-size: 28px;
            color: #069bbc;
            width: 100px;
            height: 100px;
            line-height: 100px;
            text-align: center;
          }

          .logoimg {
            width: 100px;
            height: 100px;
            display: block;
            object-fit: cover;
          }
        }
      }
    }

    .delpic {
      position: absolute;
      top: -12px;
      color: red;
      font-size: 20px;
      cursor: pointer;
    }

    .delpic1 {
      right: -15px;
    }

    .delpic2 {
      right: -134px;
    }

    .delpic3 {
      right: -250px;
    }
  }
}
</style>
