// 经营信息
export default [
  {
    path: "/business",
    meta: { title: "经营信息" },
    component: () => import("../views/business/index.vue"),
    children: [
      {
        path: "/business/financial",
        meta: { title: "财务信息", isMenu: true },
        component: () => import("../views/business/financial.vue"),
      },
      {
        path: "/business/accountStatement",
        meta: { title: "供应商对账单", isMenu: true },
        component: () => import("../views/business/accountStatement.vue"),
      },
      {
        path: "/business/productStatement",
        meta: { title: "对账单商品", isMenu: true },
        component: () => import("../views/business/productStatement.vue"),
      },
    ],
  },
]
