<!-- 对账单商品 -->

<template>
  <div class="main">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-date-picker v-model="dataValue" type="daterange" popper-class="sift-data" range-separator="至"
          start-placeholder="期初日期" end-placeholder="期末日期" @change="handleSelectData" clearable
          value-format="yyyy-MM-dd"></el-date-picker>
        <el-input v-model="barCode" placeholder="搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="productName" placeholder="搜索商品名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
        <el-input v-model="supplierName" placeholder="搜索供应商名称" suffix-icon="el-icon-search" @change="onSearch"
          clearable></el-input>
      </div>
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="230" show-overflow-tooltip prop="" label="日期">
            <template slot-scope="scope">
              <span>{{ `${scope.row.startTime} ~ ${scope.row.endTime} ` }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="supplierName" label="供应商名称"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="barCode" label="商品条形码"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="categoryName" label="分类"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="enterBox" label="入箱数"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="supplyPrice" label="供货价(元)"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="receiveBox" label="收货箱数"
            fixed="right"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="paymentAmount" label="总贷款(元)"
            fixed="right"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="settlementAmount" label="结算金额(元)"
            fixed="right"></el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="remainingSettlementAmount" label="剩余结算金额(元)"
            fixed="right"></el-table-column>
        </el-table>
      </div>
      <div class="total">
        <div class="">当前页合计：</div>
        <div class="item item1">{{ total.receiveBox ? total.receiveBox : 0 }}箱</div>
        <div class="item item2">{{ total.paymentAmount ? total.paymentAmount : 0 }}元</div>
        <div class="item item3">{{ total.settlementAmount ? total.settlementAmount : 0 }}元</div>
        <div class="item item4">{{ total.remainingSettlementAmount ? total.remainingSettlementAmount : 0 }}元</div>
      </div>
    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      barCode: "",
      productName: "",
      supplierName: "",
      dataValue: "", // 时间区间
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      // 合计
      total: {
        receiveBox: 0, // 收货箱数
        paymentAmount: 0, // 总贷款
        settlementAmount: 0, // 结算金额
        remainingSettlementAmount: 0 // 剩余结算金额
      },
    }
  },
  created() {
    const { startTime, endTime, supplierName } = this.$route.query;
    this.supplierName = supplierName || null;
    this.dataValue = startTime && endTime ? [startTime, endTime] : [];
    this.getShopPurchaseStatInfo();
  },

  methods: {
    // 获取列表
    getShopPurchaseStatInfo() {
      this.loading = true
      let params = {
        supplierName: this.supplierName || null,
        productName: this.productName || null,
        barCode: this.barCode || null,
        shopId: this.$store.state.userInfo.shopId,
        page: this.currentPage,
        pageSize: this.pageSize,
      }

      // 时间区间
      if (this.dataValue && this.dataValue.length === 2) {
        params.startTime = this.dataValue[0]
        params.endTime = this.dataValue[1]
      } else {
        params.startTime = null
        params.endTime = null
      }
      this.$axios
        .get(this.$api.getShopPurchaseStatInfo, { params })
        .then(res => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list
            this.totalItemsCount = res.data.result.totalCount

            // 计算合计
            this.calculateTotal();
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 计算合计
    calculateTotal() {
      this.total = {
        receiveBox: 0,
        paymentAmount: 0,
        settlementAmount: 0,
        remainingSettlementAmount: 0,
      };

      this.tableData.forEach(item => {
        this.total.receiveBox += item.receiveBox || 0;
        this.total.paymentAmount += item.paymentAmount || 0;
        this.total.settlementAmount += item.settlementAmount || 0;
        this.total.remainingSettlementAmount += item.remainingSettlementAmount || 0;
      });
    },
    // 清空
    clearData() {
      this.tableData = []
      this.currentPage = 1
    },
    // 搜索
    onSearch() {
      this.clearData()
      this.getShopPurchaseStatInfo()
    },
    // 时间搜索
    handleSelectData(value) {
      this.dataValue = value
      this.onSearch()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e
      this.onSearch()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getShopPurchaseStatInfo()
    },
  },
}
</script>

<style scoped lang="scss">
.main {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    .el-input {
      width: 200px;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    .el-select {
      margin-left: 20px;
      margin-bottom: 20px;
    }

    /deep/ .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    /deep/.el-range-input {
      background-color: #f2f2f2;
    }
  }

  .total {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;

    .item {
      text-align: center;
      white-space: normal;
      /* 允许文本换行 */
      word-wrap: break-word;
      /* 长单词自动换行 */
      word-break: break-word;
      /* 强制在长单词处断开 */
    }

    .item1 {
      width: 160px;
    }

    .item2 {
      width: 150px;
    }

    .item3 {
      width: 150px;
    }

    .item4 {
      width: 180px;
    }
  }
}
</style>
