<template>
  <div class="list">
    <div class="globle_border">
      <div class="search">
        <el-cascader v-model="value" :options="options" collapse-tags :props="{ expandTrigger: 'click', label: 'categoryName', value: 'categoryId', children: 'children', checkStrictly: false, emitPath: false, multiple: true }" @change="onSearch" clearable placeholder="搜索商品类目(二级)"></el-cascader>
        <el-input v-model="supplierName" placeholder="请搜索供应商名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="productName" placeholder="请搜索商品名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="barCode" placeholder="请搜索商品条形码" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="factoryName" placeholder="请搜索厂家" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-button type="primary" @click="dialogAll = true" :disabled="multipleSelection.length == 0">批量加入进货单</el-button>
      </div>
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540" row-key="biddingId" @selection-change="handleSelectionChange" ref="tableRef">
          <el-table-column type="selection" width="55" :reserve-selection="true"></el-table-column>
          <el-table-column prop="number" label="序号" width="50">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="supplierName" label="商品图片" align="center" width="100">
            <template slot-scope="scope">
              <!-- <img :src="scope.row.picUrlList && scope.row.picUrlList.length > 0 ? scope.row.picUrlList[0] : ''" alt="" style="width: 50px;height: 50px;" @click="previewImage(scope.row.picUrlList)"> -->
              <!-- <el-image src="../../assets/logo.png" :alt="scope.row.productName" style="width: 50px;height: 50px;" @click="previewImage(scope.row.logoUrl)"></el-image> -->
              <el-image class="busilicense" :src="scope.row.picUrlList && scope.row.picUrlList.length > 0 ? scope.row.picUrlList[0] : ''" fit="cover" @mouseover="previewImage(scope.row.picUrlList)"></el-image>
            </template>
          </el-table-column>
          <el-table-column min-width="100" prop="supplierName" label="供应商名称" show-overflow-tooltip></el-table-column>
          <el-table-column min-width="100" prop="barCode" label="商品条形码" show-overflow-tooltip></el-table-column>
          <el-table-column prop="productName" label="商品名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="specs" label="规格"></el-table-column>
          <el-table-column min-width="100" prop="categoryName" label="商品类目" show-overflow-tooltip></el-table-column>
          <el-table-column prop="enterBox" label="入箱数"></el-table-column>
          <el-table-column min-width="100" prop="factoryName" label="厂家" show-overflow-tooltip></el-table-column>
          <el-table-column prop="brand" label="品牌"></el-table-column>
          <el-table-column min-width="150" prop="validityPeriod" label="保质期（天）"></el-table-column>
          <el-table-column min-width="150" prop="suggestPrice" label="建议零售价（元）"></el-table-column>
          <el-table-column min-width="150" prop="supplyPrice" label="进货价（元）"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" size="small" @click="onWithdrawal(scope.row)">加入订单</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
    <!-- 批量 -->
    <el-dialog title="加入订单" :visible.sync="dialogAll" :before-close="onCancel">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-form-item label="商品箱数" prop="productBox">
          <el-input-number v-model.number="ruleForm.productBox" :min="1" :step="1" :max="Infinity" @change="onchangeAll"></el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitFormAll('ruleForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 单个 -->
    <el-dialog title="加入订单" :visible.sync="dialogVisible" :before-close="onCancel">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品名称" prop="shopName">
              <el-input v-model="ruleForm.shopName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品规格" prop="specs">
              <el-input v-model="ruleForm.specs" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="入箱数" prop="enterBox">
              <el-input v-model="ruleForm.enterBox" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="商品箱数" prop="productBox">
              <el-input-number v-model.number="ruleForm.productBox" :min="1" :step="1" :max="Infinity" @change="onchange"></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="商品数量" prop="shopNumber">
              <el-input v-model="ruleForm.shopNumber" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"></el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" :loading="submitLoding">确 定</el-button>
      </span>
    </el-dialog>
    <el-image-viewer
      v-if="showViewer"
      :on-close="
        () => {
          showViewer = false
        }
      "
      :url-list="imgList"
    ></el-image-viewer>
  </div>
</template>

<script>
export default {
  components: {
    "el-image-viewer": () => import("element-ui/packages/image/src/image-viewer"),
  },
  data() {
    return {
      // 图片预览
      showViewer: false,
      imgList: [],
      number: 1,
      dialogVisible: false,
      submitLoding: false,
      dialogAll: false,
      ruleForm: {
        shopNumber: "",
        enterBox: "",
        specs: "",
        shopName: "",
        supplierId: "",
        productId: "",
        productBox: 1,
        supplyPrice: '',
        supplierSupplyPrice:""
      },
      multipleSelection: [],
      rules: {
        shopNumber: [
          { required: true, message: "数量不能少于", trigger: "submit" },
          {
            validator: function (rule, value, callback) {
              if (value === "" || value === isNaN) {
                callback(new Error("请输入一个数字"))
              } else if (!Number.isInteger(value)) {
                callback(new Error("请输入一个整数"))
              } else {
                callback()
              }
            },
            trigger: "blur",
          },
        ],
      },
      loading: false,
      factoryName: "",
      productName: "",
      barCode: "",
      supplierName: "",
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [5, 10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      value: "",
      options: [],
      shopId: "",
      productList: [],
    }
  },
  created() {
    this.getPurchaseList()
    // this.getCategoryLIst()
    this.getCategoryInfoList()
    this.shopId = this.$store.state.userInfo.shopId
  },
  methods: {
    // 图片放大
    previewImage(url) {
      this.imgList = url
      this.showViewer = true
    },
    // 商超列表
    getPurchaseList() {
      // let value = ''
      // if (this.value.length > 0) value = JSON.stringify(this.value)
      // if(this.value) value = this.value.join()
      // console.log(this.value);

      // value = encodeURIComponent(value)
      this.$axios
        .post(this.$api.purchaseList, {
          factoryName: this.factoryName,
          productName: this.productName,
          barCode: this.barCode,
          supplierName: this.supplierName,
          categoryIds: this.value || [],
          page: this.currentPage,
          pageSize: this.pageSize,
        })
        .then(res => {
          if (res.data.result.list == null) {
            this.tableData = []
          } else {
            this.tableData = res.data.result.list
          }
          this.totalItemsCount = res.data.result.totalCount
        })
        .catch(() => {})
    },
    // getCategoryLIst() {
    //   this.$axios.get(this.$api.categoryLIst, {
    //     // params: {
    //     //   // auditStatus: 1,
    //     //   page: this.currentPage,
    //     //   pageSize: 9999,
    //     // },
    //   }).then((res) => {
    //     this.options = res.data.result.list
    //   }).catch(() => {
    //   });
    // },
    getCategoryInfoList() {
      this.$axios
        .get(this.$api.getCategoryInfoList, {
          params: {
            auditStatus: "1",
            page: 1,
            pageSize: 9999,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.options = res.data.result.list
            this.options.map(item => {
              if (item.children.length == 0) {
                item.children = null
              }
            })
          }
          // this.loading = false
        })
    },
    // 选中
    handleSelectionChange(val) {
      this.multipleSelection = val
      // console.log(this.multipleSelection)
    },

    // 搜索
    onSearch() {
      // console.log(this.value);
      this.tableData = []
      this.currentPage = 1
      this.getPurchaseList()
    },

    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getPurchaseList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getPurchaseList()
    },

    // 加入订单
    onWithdrawal(row) {
      this.dialogVisible = true
      let data = Object.assign({}, row)
      this.number = data.enterBox
      // this.ruleForm.shopNumber = data.enterBox
      this.ruleForm.enterBox = data.enterBox
      this.ruleForm.specs = data.specs
      this.ruleForm.shopName = data.productName
      this.ruleForm.supplierId = data.supplierId
      this.ruleForm.productId = data.productId
      this.ruleForm.shopNumber = row.enterBox * this.ruleForm.productBox
      this.ruleForm.supplierSupplyPrice = data.biddingPrice
      this.ruleForm.supplyPrice = data.supplyPrice
      // this.ruleForm.enterBox= data.enterBox
      // console.log(row.enterBox * this.ruleForm.shopNumber);
    },
    onchange(value) {
      if (!Number.isInteger(value)) {
        this.ruleForm.productBox = Math.floor(value)
        this.$message.warning("只能输入整数")
      }
      // console.log(this.ruleForm.productBox)
      this.ruleForm.shopNumber = this.ruleForm.enterBox * this.ruleForm.productBox
    },
    // 批量
    onchangeAll() {
      this.ruleForm.shopNumber = this.ruleForm.enterBox * this.ruleForm.productBox
    },
    // 关闭对话框
    onCancel() {
      this.resetForm()
      this.dialogVisible = false
      this.dialogAll = false
    },
    // 重置表单
    resetForm() {
      this.ruleForm = {
        shopNumber: 1,
        enterBox: "",
        specs: "",
        shopName: "",
        supplierId: "",
        productId: "",
        productBox: "",
      }
      this.$refs.ruleForm.resetFields()
      this.$refs.tableRef.clearSelection()
    },
    // 提交表单
    submitForm(formName) {
      const { productId, shopNumber, supplierId, productBox ,supplyPrice,enterBox,supplierSupplyPrice} = this.ruleForm
      //将shopNumber，productId，字段存进productList[]数组里去重，
      this.productList.push({
        productId: productId,
        quantity: shopNumber,
        supplierId: supplierId,
        productBox: productBox,
        supplierSupplyPrice:supplierSupplyPrice,
        supplyPrice: supplyPrice,
        enterBox:enterBox,
      })
      // console.log(this.productList);
      // console.log(formName);
      this.submitLoding = true
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios.post(this.$api.save_purchase_cart, {
              type: 1,
              shopId: this.shopId,
              productList: this.productList,
            }).then(res => {
              if (res.data.code == 100) {
                this.$message.success("加入成功")
                this.productList = []
                this.getPurchaseList()
                this.submitLoding = false
                this.dialogVisible = false
                this.resetForm()
              }
            })
        } else {
          this.submitLoding = false
          this.$message.error("加入失败")
          return false
        }
      })
    },
    // 批量提交
    submitFormAll(formName) {
      const { productBox } = this.ruleForm
      // console.log(shopNumber, productBox)
      this.multipleSelection = this.multipleSelection.map(t => {
        t.productBox = productBox
        t.quantity = t.productBox * t.enterBox
        return t
      })
      // console.log('multipleSelection',this.multipleSelection);
      // 从this.multipleSelection 数组中提取enterBox字段，productBox字段，productId字段，supplierId字段，shopNumber字段返回数组
      this.productList = this.multipleSelection.map(t => {
        return {
          enterBox: t.enterBox,
          productBox: t.productBox,
          productId: t.productId,
          supplierId: t.supplierId,
          quantity: t.quantity,
          supplyPrice: t.supplyPrice,
          supplierSupplyPrice:t.biddingPrice,
          // enterBox:t.enterBox
        }
      })
      // console.log(this.productList);
      // console.log(formName);
      this.submitLoding = true
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$axios.post(this.$api.save_purchase_cart, {
              type: 1,
              shopId: this.shopId,
              productList: this.productList,
            })
            .then(res => {
              if (res.data.code == 100) {
                this.$message.success("加入成功")
                this.productList = []
                this.submitLoding = false
                this.dialogAll = false
                this.resetForm()
                this.getPurchaseList()
              }
            })
        } else {
          this.submitLoding = false
          this.$message.error("加入失败")
          return false
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.list {
  .busilicense {
    display: block;
    width: 80px;
    height: 58px;
    border-radius: 4px;
  }
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    // height: 44px;

    .el-cascader {
      // width: 200px;
      margin-bottom: 20px;
    }

    .el-input {
      margin-left: 20px;
      margin-bottom: 20px;
      width: 160px;
    }

    .el-button {
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }

  .show_img {
    color: #069bbc;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}

.el-input-number {
  width: 255px;
}
</style>
